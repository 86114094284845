import React, { Component } from "react"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { withRouter } from "react-router-dom"
import { css } from "@emotion/core"
import AdminVoucherForm from "./AdminVoucherForm"
import AdminMainLayout from './AdminMainLayout'
import { adminVouchersList, generateVoucherCode } from "../actions/admin_vouchers"
import {
  galleryEventDropdownOptions,
  galleryDropdownOptions,
  photographerDropdownOptions,
  currencyDropdownOptions,
  albumDropdownOptions,
  voucherTypeDropdownOptions,
  voucherStatusDropdownOptions,
} from '../actions/admin_dropdown_options'

class AdminVoucher extends Component {
  componentDidMount() {
    const { dispatch, voucher_id, galleryEventOptions } = this.props
    dispatch(adminVouchersList.invalidateList())
    dispatch(adminVouchersList.ensureObjectLoaded(voucher_id))
    dispatch(galleryEventDropdownOptions.fetchListIfNeeded())
    dispatch(galleryDropdownOptions.fetchListIfNeeded())
    dispatch(photographerDropdownOptions.fetchListIfNeeded())
    dispatch(currencyDropdownOptions.fetchListIfNeeded())
    dispatch(albumDropdownOptions.fetchListIfNeeded())
    dispatch(voucherTypeDropdownOptions.fetchListIfNeeded())
    dispatch(voucherStatusDropdownOptions.fetchListIfNeeded())
  }

  onSave = (values) => {
    const { dispatch, history, voucher_id } = this.props
    const on_done = function () {
      dispatch(adminVouchersList.invalidateList())
      dispatch(adminVouchersList.fetchListIfNeeded())
      history.push("/admin/vouchers")
    }
    if (voucher_id) {
      values.id = voucher_id
      return dispatch(adminVouchersList.saveObject(values)).then(on_done)
    } else {
      return dispatch(adminVouchersList.saveNewObject(values)).then(on_done)
    }
  }

  getInitialValues = () => {
    const { voucher } = this.props
    return voucher && voucher.id ? voucher : {
      'voucher_type': 'monetary',
      'code': '',
      'status': 'active',
      'photographer': '',
      'gallery': '',
      'album': '',
      'event': '',
      'country': '',
      'max_amount': null,
      'max_photos': null,
    }
  }

  render() {
    const {
      voucher, galleryEventOptions, galleryOptions, photographerOptions, currencyOptions,
      albumOptions, voucherTypeOptions, voucherStatusOptions,
    } = this.props
    
    const initial_values = this.getInitialValues()

    return (
      <AdminMainLayout>
        <h2>
          {`${initial_values && initial_values.id ? "Edit" : "Add new"}`} voucher
        </h2>
        <AdminVoucherForm
          onSave={ this.onSave }
          voucher={ voucher }
          galleryEventOptions={ galleryEventOptions }
          galleryOptions={ galleryOptions }
          photographerOptions={ photographerOptions }
          currencyOptions={ currencyOptions }
          albumOptions={ albumOptions }
          voucherTypeOptions={ voucherTypeOptions }
          voucherStatusOptions={ voucherStatusOptions }
          generateVoucherCode={ generateVoucherCode }
          initial_values={ initial_values }
        />
      </AdminMainLayout>
    )
  }
}
function mapStateToProps(state, props) {
  const { voucher_id } = props.match.params
  const voucher = adminVouchersList.getObject(voucher_id)
  const galleryEventOptions = galleryEventDropdownOptions.getVisibleObjects()
  const galleryOptions = galleryDropdownOptions.getVisibleObjects()
  const photographerOptions = photographerDropdownOptions.getVisibleObjects()
  const currencyOptions = currencyDropdownOptions.getVisibleObjects()
  const albumOptions = albumDropdownOptions.getVisibleObjects()
  const voucherTypeOptions = voucherTypeDropdownOptions.getVisibleObjects()
  const voucherStatusOptions = voucherStatusDropdownOptions.getVisibleObjects()
  return {
    voucher_id,
    voucher,
    galleryEventOptions,
    galleryOptions,
    photographerOptions,
    currencyOptions,
    albumOptions,
    voucherTypeOptions,
    voucherStatusOptions,
    is_busy: adminVouchersList.isLoading()
  }
}
export default withRouter(connect(mapStateToProps)(AdminVoucher))

const image = css`
  width: 80%;
`
