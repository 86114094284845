import { ItemList } from "../../../orm"

class AdminPrintFramesList extends ItemList {

  getEntityKey() {
    return "admin/print_frame";
  }

  trySetFilter(newFilter) {
    return async (dispatch) => {
        const existingFilter = this.getFilter()
        if ( JSON.stringify(existingFilter) === JSON.stringify(newFilter) ) {
            return
        }
        dispatch(this.updateListFilter(newFilter))
        dispatch(this.updatePaginationNumItemsPerPage(20))
        dispatch(this.fetchListIfNeeded())
    }
}

}

export const adminPrintFramesList = new AdminPrintFramesList("admin_print_frames__default")
export const adminPrintFrameOptionsList = new AdminPrintFramesList("admin_print_frames_options__default")