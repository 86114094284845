import { ItemList } from "../../orm";
import { get } from "lodash";

class AdminGalleryEventsList extends ItemList {

  getEntityKey() {
    return "admin/gallery_event";
  }

}

export const adminGalleryEventsList = new AdminGalleryEventsList("admin_gallery_events__default");