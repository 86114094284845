import React, { useState, useEffect } from "react"
import { get, map } from 'lodash'
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux'
import AddPrintItemButton from './AddPrintItemButton'
import AvailablePrintFrameForm from "./AvailablePrintFrameForm"
import { adminPrintFramesList } from '../../actions/print/admin_print_frames'

export default function AvailablePrintFrames({ country_print_configuration_id }) {
  const dispatch = useDispatch()
  const { formState: { errors } } = useForm()
  const printFrameOptions = useSelector(() => adminPrintFramesList.getVisibleObjects())
  const [isAddingPrintFrameOption, setIsAddingPrintFrameOption] = useState(false)

  useEffect(() => {
    async function fetchStaticObjects() {
        dispatch(adminPrintFramesList.trySetFilter({ country_print_configuration: country_print_configuration_id }))
        dispatch(adminPrintFramesList.fetchListIfNeeded())
    }
    fetchStaticObjects()
  }, [])

  const onAddPrintFrameOption = () => {
    setIsAddingPrintFrameOption(true)
  }

  const onUpdated = () => {
    setIsAddingPrintFrameOption(false)
  }

  return (
    <>
    {map(printFrameOptions, (printFrameOption) =>
      <>
        <AvailablePrintFrameForm key={get(printFrameOption, "id")} printFrameOption={printFrameOption} onUpdated={onUpdated} country_print_configuration_id={country_print_configuration_id}/>
      </>
     )}
     { isAddingPrintFrameOption && 
        <AvailablePrintFrameForm printFrameOption={null}
                                 country_print_configuration_id={country_print_configuration_id} 
                                 onUpdated={onUpdated}
        /> 
      }
      <AddPrintItemButton on_click_action={onAddPrintFrameOption}/>
    </>
  )
}
