import { ItemList } from "../../orm";
import { get } from "lodash";

class AdminPhotoArtList extends ItemList {

  getEntityKey() {
    return "admin/photo_art";
  }

}

export const adminPhotoArtList = new AdminPhotoArtList("admin_photo_art_photographs__default");