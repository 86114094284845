import { ItemList } from "../../orm";
import { v4 as uuidv4 } from 'uuid'

class AdminVouchersList extends ItemList {

  getEntityKey() {
    return "admin/voucher";
  }

}

export const generateVoucherCode = (setFieldValue) => {
  let code = uuidv4()
  code = code.replace(/-/g, "")
  code = code.substring(0,12)
  setFieldValue('code',code)    
}

export const adminVouchersList = new AdminVouchersList("admin_vouchers__default");

