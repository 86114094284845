import { ItemList } from "../../../orm"

class AdminPrintPurchasesList extends ItemList {

  getEntityKey() {
    return "admin/print_purchase_option"
  }

  trySetFilter(newFilter) {
    return async (dispatch) => {
        const existingFilter = this.getFilter()
        if ( JSON.stringify(existingFilter) === JSON.stringify(newFilter) ) {
            return
        }
        dispatch(this.updateListFilter(newFilter))
        dispatch(this.updatePaginationNumItemsPerPage(20))
        dispatch(this.fetchListIfNeeded())
    }
}

}

export const adminPrintPurchasesList = new AdminPrintPurchasesList("admin_print_purchases__default")