import React, { useState, useEffect } from "react"
import { get, map } from 'lodash'
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux'
import AddPrintItemButton from './AddPrintItemButton'
import { adminPrintSizeOptionsList, adminPrintSizesList } from '../../actions/print/admin_print_sizes'
import AvailablePrintSizeForm from "./AvailablePrintSizeForm"

export default function AvailablePrintSizes({ country_print_configuration_id }) {
  const dispatch = useDispatch()
  const { formState: { errors } } = useForm()
  const printSizeOptions = useSelector(() => adminPrintSizesList.getVisibleObjects())
  const [isAddingPrintSizeOption, setIsAddingPrintSizeOption] = useState(false)

  useEffect(() => {
    async function fetchStaticObjects() {
        dispatch(adminPrintSizesList.trySetFilter({ country_print_configuration: country_print_configuration_id }))
        dispatch(adminPrintSizesList.fetchListIfNeeded())
    }
    fetchStaticObjects()
  }, [])

  const onAddPrintSizeOption = () => {
    setIsAddingPrintSizeOption(true)
  }

  const onUpdated = () => {
    setIsAddingPrintSizeOption(false)
  }

  return (
    <>
      {map(printSizeOptions, (printSizeOption) =>
        <>
          <AvailablePrintSizeForm key={get(printSizeOption, "id")} printSizeOption={printSizeOption} onUpdated={onUpdated} country_print_configuration_id={country_print_configuration_id}/>
        </>
      )}
      { isAddingPrintSizeOption && 
        <AvailablePrintSizeForm printSizeOption={null}
                                country_print_configuration_id={country_print_configuration_id} 
                                onUpdated={onUpdated}
        /> 
      }
      <AddPrintItemButton on_click_action={onAddPrintSizeOption}/>
    </>
  )
}