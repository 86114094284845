import React from "react"
import { Tooltip, makeStyles, } from "@material-ui/core"
import { Button, Typography } from "@material-ui/core"

const useStyles = makeStyles({
    unset_button: {
        minWidth: "120px",
        
        backgroundColor: "#dee2e6 !important",
        color: "#0c59cf",
        boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.69)",
        borderRadius: 5,
        textTransform: "capitalize",
        width: "29%",
        textAlign: "center",
        marginTop: 5,
    },
    set_button: {
        
        alignItems:'center',
        /* backgroundColor: "#dee2e6 !important", */
        backgroundColor: "#75A9F9 !important",
        color: "#0c59cf",
        boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.69)",
        borderRadius: 5,
        textTransform: "capitalize",
        width: "29%",
        textAlign: "center",
        marginTop: 5,
    },
    unset_text: {
        color: "#0c59cf",
        fontSize: "15px !important",
        fontWeight: "600",
        padding: 5,
    },
    set_text: {
        color: "#0c59cf",
        fontSize: "15px !important",
        fontWeight: "600",
        padding: 5,
    }
})

export const FormButton = ({isValueSet, children, ...props}) => {
    const classes = useStyles()
    return (
        <Button className={isValueSet ? classes.set_button : classes.unset_button} {...props}>
          <Typography className={isValueSet ? classes.set_text : classes.unset_text} >
            {children}
          </Typography>
        </Button>
    )
}
