import React, { Component } from "react"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { withRouter } from "react-router-dom"
import { css } from "@emotion/core"
import AdminGalleryEventForm from "./AdminGalleryEventForm"
import AdminMainLayout from './AdminMainLayout'
import { adminGalleryEventsList } from "../actions/admin_gallery_events"

class AdminGalleryEvent extends Component {
  componentDidMount() {
    const { dispatch, gallery_event_id } = this.props
    dispatch(adminGalleryEventsList.invalidateList())
    dispatch(adminGalleryEventsList.ensureObjectLoaded(gallery_event_id))
  }

  onSave = (values) => {
    const { dispatch, history, gallery_event_id } = this.props
      const on_done = function () {
          dispatch(adminGalleryEventsList.invalidateList())
          dispatch(adminGalleryEventsList.fetchListIfNeeded())
          history.push("/admin/gallery_events")
        }
        if (gallery_event_id) {
          values.id = gallery_event_id
          return dispatch(adminGalleryEventsList.saveObject(values)).then(on_done)
        } else {
          return dispatch(adminGalleryEventsList.saveNewObject(values)).then(on_done)
        }
  }
  
  render() {
    const {gallery_event, initial_values } = this.props
      return (
          <AdminMainLayout>
            <h2>
              {`${isEmpty(initial_values) ? "Add new" : "Edit"}`} gallery event
            </h2>
            <AdminGalleryEventForm
              onSave={ this.onSave }
              gallery_event={ gallery_event }
              initial_values={ initial_values }
            />
          </AdminMainLayout>
      )
  }
}
function mapStateToProps(state, props) {
  const { gallery_event_id } = props.match.params 
  const gallery_event = adminGalleryEventsList.getObject(gallery_event_id)
  const initial_values = gallery_event
  return {
    gallery_event_id,
    gallery_event,
    initial_values,
    is_busy: adminGalleryEventsList.isLoading()
  }
}
export default withRouter(connect(mapStateToProps)(AdminGalleryEvent))

const image = css`
  width: 80%;
`
