import { ItemList } from "../../orm"

class AdminCache extends ItemList {

    getEntityKey() {
        return "admin/cache";
    }

    resetCache() {
      return this.saveNewObject({
        action: "reset_cache"
      })
    }

}

export const adminCacheList = new AdminCache("admin_cache__default");
