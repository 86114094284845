import { ItemList } from "../../orm"

class AdminBillingAddressList extends ItemList {

  getEntityKey() {
    return "admin/billing_address"
  }

  trySetFilter(newFilter) {
    return async (dispatch) => {
        const existingFilter = this.getFilter()
        if ( JSON.stringify(existingFilter) === JSON.stringify(newFilter) ) {
            return
        }
        dispatch(this.updateListFilter(newFilter))
        dispatch(this.updatePaginationNumItemsPerPage(20))
        dispatch(this.fetchListIfNeeded())
    }
}

}

export const adminBillingAddressList = new AdminBillingAddressList("admin_billing_address__default")