import React, { Component } from "react"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { withRouter } from "react-router-dom"
import { css } from "@emotion/core"
import AdminGenreTagForm from "./AdminGenreTagForm"
import AdminMainLayout from './AdminMainLayout'
import { adminGenreTagsList } from "../actions/admin_genre_tags"

class AdminGenreTag extends Component {
  componentDidMount() {
    const { dispatch, genre_tag_id } = this.props
    dispatch(adminGenreTagsList.invalidateList())
    dispatch(adminGenreTagsList.ensureObjectLoaded(genre_tag_id))
  }

  onSave = (values) => {
    const { dispatch, history, genre_tag_id } = this.props
      const on_done = function () {
          dispatch(adminGenreTagsList.invalidateList())
          dispatch(adminGenreTagsList.fetchListIfNeeded())
          history.push("/admin/genre_tags")
        }
        if (genre_tag_id) {
          values.id = genre_tag_id
          return dispatch(adminGenreTagsList.saveObject(values)).then(on_done)
        } else {
          return dispatch(adminGenreTagsList.saveNewObject(values)).then(on_done)
        }
  }
  
  render() {
    const {genre_tag, initial_values } = this.props
      return (
          <AdminMainLayout>
            <h2>{`${isEmpty(initial_values) ? "Add new" : "Edit"}`} genre</h2>
            <AdminGenreTagForm onSave={this.onSave} genre_tag={genre_tag} initial_values={initial_values} />
          </AdminMainLayout>
      )
  }
}

function mapStateToProps(state, props) {
  const { genre_tag_id } = props.match.params 
  const genre_tag = adminGenreTagsList.getObject(genre_tag_id)
  const initial_values = genre_tag
  return {
    genre_tag_id,
    genre_tag,
    initial_values,
    is_busy: adminGenreTagsList.isLoading()
  }
}
export default withRouter(connect(mapStateToProps)(AdminGenreTag))

const image = css`
  width: 80%;
`