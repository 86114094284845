import { ItemList } from "../../orm";
import { get } from "lodash";

class AdminCurrencyConversionList extends ItemList {

  getEntityKey() {
    return "admin/currency_conversion";
  }

}

export const adminCurrencyConversionList = new AdminCurrencyConversionList("admin_currency_conversion__default");