import React, { useState, useEffect } from "react"
import { get, map } from 'lodash'
import { useForm, Controller } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux'
import {showSuccess, showError } from '../../../actions/Error'
import { makeStyles } from "@material-ui/core"
import { handleSubmitResultReactHookForm } from '../../../actions/form'
import { MaterialInput } from '../../../components/form/materialui/MaterialInput'
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import { adminPrintTypeOptionsList } from '../../actions/print/admin_print_types'
import { adminPrintSizeOptionsList } from '../../actions/print/admin_print_sizes'
import { adminPrintPaperOptionsList } from '../../actions/print/admin_print_paper'
import { adminPrintFrameOptionsList } from '../../actions/print/admin_print_frames'
import { adminPrintPurchasesList } from '../../actions/print/admin_print_purchase_options'
import SavePrintItemButton from "./SavePrintItemButton"
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer} from '@material-ui/core'
import { MaterialDropdown } from "../form/MaterialDropdown"

const useStyles = makeStyles({
    modalContainer: {
        background: "white",
    },
    markdownPreview: {
        width: "250px",
        cursor: "pointer",
        overflow: "scroll"
    }
})

export const AvailablePurchaseOptionForm = ({
  onUpdated,
  printPurchaseOption,
  country_print_configuration_id
}) => {

  const dispatch = useDispatch()
  const classes = useStyles()
  
  const defaultValues = { 
    price: get(printPurchaseOption, "price"),
    enabled: get(printPurchaseOption, "enabled"),
    print_type: get(printPurchaseOption, "print_type", "a744ad27-8ad7-46e2-a98c-6a5747d5c2df"),
    print_size: get(printPurchaseOption, "print_size"),
    print_frame: get(printPurchaseOption, "print_frame"),
    print_paper: get(printPurchaseOption, "print_paper"),
    description: get(printPurchaseOption, "description"),
    print_supplier: get(printPurchaseOption, "print_supplier"),
    print_size_name: get(printPurchaseOption, "print_size_name"),
  }

  const printSizeOptions = useSelector(() => adminPrintSizeOptionsList.getAsOptions())
  const printTypeOptions = useSelector(() => adminPrintTypeOptionsList.getAsOptions())
  const printPaperOptions = useSelector(() => adminPrintPaperOptionsList.getAsOptions())
  const printFrameOptions = useSelector(() => adminPrintFrameOptionsList.getAsOptions())
  const formProps = useForm({ defaultValues: defaultValues })

  const {
    reset,
    watch,
    control,
    setValue,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formProps
  
  const is_watched = watch()

  useEffect(() => {
    dispatch(adminPrintSizeOptionsList.trySetFilter({ country_print_configuration: country_print_configuration_id, enabled: true }))
    dispatch(adminPrintSizeOptionsList.fetchListIfNeeded())
    
    dispatch(adminPrintTypeOptionsList.trySetFilter({ country_print_configuration: country_print_configuration_id, enabled: true }))
    dispatch(adminPrintTypeOptionsList.fetchListIfNeeded())
    
    dispatch(adminPrintPaperOptionsList.trySetFilter({ country_print_configuration: country_print_configuration_id, enabled: true }))
    dispatch(adminPrintPaperOptionsList.fetchListIfNeeded())
    
    dispatch(adminPrintFrameOptionsList.trySetFilter({ country_print_configuration: country_print_configuration_id, enabled: true }))
    dispatch(adminPrintFrameOptionsList.fetchListIfNeeded())
  },[])

  const onSubmit = (values) => {
    values.country_print_configuration = country_print_configuration_id

    const on_ok = function () {
      dispatch(adminPrintPurchasesList.invalidateList())
      dispatch(adminPrintPurchasesList.fetchListIfNeeded())
      onUpdated()
      showSuccess("Success", "Record Saved")
    }
    
    if (printPurchaseOption) {
      values.id = printPurchaseOption.id
      return dispatch(adminPrintPurchasesList.saveObject(values)).then((res) => handleSubmitResultReactHookForm({res, hook_form: formProps, on_ok}))
    }else {
      return dispatch(adminPrintPurchasesList.saveNewObject(values)).then((res) => handleSubmitResultReactHookForm({res, hook_form: formProps, on_ok}))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Print Type</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Frame Type</TableCell>
            <TableCell>Paper Type</TableCell>
            <TableCell>Supplier</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Price (local currency)</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <MaterialInput name="enabled" form={formProps} type="checkbox" defaultChecked={printPurchaseOption === null}/>
            </TableCell>
            <TableCell>
              <Controller
                name="print_type"
                control={control}
                render={({ field }) => (
                  <>
                    <MaterialDropdown
                      form={formProps}
                      options={printTypeOptions}
                      {...field}
                    />
                  </>
                )}
              />
            </TableCell>
            <TableCell>
              <Controller
                name="print_size"
                control={control}
                render={({ field }) => (
                  <>
                    <MaterialDropdown
                      form={formProps}
                      options={printSizeOptions}
                      {...field}
                    />
                  </>
                )}
              />
            </TableCell>
            <TableCell>
              <input {...register("print_size_name")}/>
            </TableCell>
            <TableCell>
              <Controller
                name="print_frame"
                control={control}
                render={({ field }) => (
                  <>
                    <MaterialDropdown
                      form={formProps}
                      options={printFrameOptions}
                      {...field}
                    />
                  </>
                )}
              />
            </TableCell>
            <TableCell>
              <Controller
                name="print_paper"
                control={control}
                render={({ field }) => (
                  <>
                    <MaterialDropdown
                      form={formProps}
                      options={printPaperOptions}
                      {...field}
                    />
                  </>
                )}
              />
            </TableCell>
            <TableCell>
              <input {...register("print_supplier")}/>
            </TableCell>
            <TableCell>
            <input {...register("description")}/>
            </TableCell>
            <TableCell>
              <MaterialInput name="price" form={formProps} type="number" step=".01" />
            </TableCell>
            <TableCell>
              <Button type="submit">
                <SavePrintItemButton/>
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </form>
  )
}
