import React, { Component } from 'react'
import { get } from 'lodash'
import { Formik, Form  } from 'formik'
import { Button } from '@material-ui/core'
import FormCard from '../../components/layout/FormCard'
import { FileUploader } from '../../components/form/FileUploader'
import { FormikInputField } from '../../components/form/InputField'
import { FormikCheckboxField } from '../../components/form/CheckboxField'


class AdminPromotionalMediaForm extends Component {
    
  render() {
    
    const { onSave, initial_values, promotional_event } = this.props
    const promotional_photograph_url = get(promotional_event, ['promotional_photograph', 'gallery_size_file_info', 'download_url'])
    
    const onFileUploaded = (formik_props, file_info) => 
          formik_props.setFieldValue('promotional_photograph_file_info_for_saving', file_info)
      
    return (
      <Formik
        enableReinitialize
        onSubmit={ onSave }
        initialValues={ initial_values }
      >
        {(formik_props) => {
          const { values } = formik_props
          return (
            <Form>
              <FormCard>
                <FormikInputField
                  name='name'
                  type='text'
                  label='Name'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikCheckboxField
                  name='enabled'
                  type='checkbox'
                  label='Enable'
                  checked={ values.enabled }
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FileUploader
                  label='Image'
                  formik_props={ formik_props }
                  name='promotional_photograph_file_info_for_saving'
                  onChange={ (file_info) => onFileUploaded(formik_props, file_info) } 
                />
                {promotional_photograph_url && (
                  <div>
                    <h3>
                      Current image:
                    </h3>
                    <div>
                      <img src={ promotional_photograph_url }/>
                    </div>
                  </div>
                )} 
              </FormCard>
              <FormCard>
                <Button
                  fullWidth
                  type='submit'
                  size='large'
                  color='primary'
                  variant='contained'
                >
                  Save
                </Button>
              </FormCard>
            </Form>
          )
        }}
      </Formik>
    )
  }
}
export default AdminPromotionalMediaForm
