import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { default_theme as theme } from '../../emotion/theme'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
    root: {
        padding: '10px 16px',
        borderBottom: '1px solid #cccccc',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.grey[500],
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flexGrow: 1,
    },
    loading: {
        color: '#ffffff'
    }
})

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
          ) : null}
        </MuiDialogTitle>
    )
})

export const Modal = ({children, title, onClose, onSave, ...props}) => {

    return (
        <Dialog open={true}
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                {...props}
        >
          <>
            {title &&
             <DialogTitle id="customized-dialog-title" onClose={onClose}>
               {title}
               
             </DialogTitle>
            }
          </>
          <div>
            {children}
          </div>
        </Dialog>
    )
}
