import React, { useState, useEffect } from "react"
import { get, head } from "lodash"
import { Button } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { adminPhotoArtList } from "../actions/admin_photo_art"

export default function AdminPhotoArtForm({ photo_art }) {
  const dispatch = useDispatch()
  const history = useHistory()
  
  const defaultValues = { 
    rating: get(photo_art, "rating"),
    description: get(photo_art, "description"),
    available_to_purchase: get(photo_art, "available_to_purchase")
  }
  
  const formProps = useForm({ defaultValues: defaultValues })
  const {
    register,
    getValues,
    handleSubmit,
    shouldValidate,
    formState: { errors },
  } = formProps

  const onSubmit = (values) => {
    const on_done = function () {
      dispatch(adminPhotoArtList.fetchListIfNeeded())
      history.push("/admin/photo_arts")
    }
    values.id = get(photo_art, "id")
    return dispatch(adminPhotoArtList.saveObject(values)).then(on_done)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Rating </label>
        <br/>
        <input
          type="number"
          min="0" 
          max="100"
          step="0.01"
          placeholder="Rating"
          {...register("rating")}
        />
        <br/>
          <label>Available to Purchase</label>
          <input 
            type="checkbox"
            {...register("available_to_purchase")}
            shouldValidate={true}
          />
          <br/>
        <label>Description</label>
        <br/>
        <textarea
          name="description"
          rows="4"
          {...register("description")}
        >
        </textarea>
        <br/>
        <Button type="submit">submit</Button>
      </form>
      <div>
        <h3>Photo Art #{get(photo_art, "primary_key")}:</h3>
        <div><img src={get(head(get(photo_art, "photographs")), ["thumbnail_size_file_info", "download_url"])}/></div>
      </div>
    </>
  )
}
