import React, { Component } from 'react'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { Formik, Form  } from 'formik'
import FormCard from '../../components/layout/FormCard'
import * as Yup from 'yup'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { FormikDateTimePicker } from '../../components/form/DatetimePicker'
import { Button } from '@material-ui/core'

const validationSchema = Yup.object().shape({
  voucher_type: Yup.string().required('Required'),
  country: Yup.string().nullable(true).when('voucher_type', {
    is: (value) => value == 'monetary',
    then: (s) => s.required('Required for Monetary vouchers')
  }),
  max_amount: Yup.number().nullable(true).when('voucher_type', {
    is: (value) => value == 'monetary',
    then: (s) => s.required('Required for Monetary vouchers')
  }),
  max_photos: Yup.number().nullable(true).when('voucher_type', {
    is: (value) => value == 'photos',
    then: (s) => s.required('Required for Photos vouchers')
  }),
  code: Yup.string().required('Required'),
  email: Yup.string().email().nullable(true),
  status: Yup.string().required('Required'),
})

class AdminVoucherForm extends Component {

  

  render() {
    const {
      onSave,
      voucher,
      initial_values,
      galleryEventOptions,
      galleryOptions,
      photographerOptions,
      currencyOptions,
      albumOptions,
      voucherTypeOptions,
      voucherStatusOptions,
      generateVoucherCode,
    } = this.props


    return (
      <Formik
        enableReinitialize
        onSubmit={ onSave }
        initialValues={ initial_values }
        validationSchema={ validationSchema }
      >
        {(formik_props) => {
          const { values, setFieldValue } = formik_props

          return (
            <Form>
              <FormCard>
                <FormikDropdownField
                  name='voucher_type'
                  options={ voucherTypeOptions }
                  label='Select Voucher Type'
                  formik_props={ formik_props }
                />
              </FormCard>
              {
                values && values.voucher_type && values.voucher_type == 'monetary' &&
                <>
                  <FormCard>
                    <FormikDropdownField
                      name='country'
                      options={ currencyOptions }
                      label='Select Currency'
                      formik_props={ formik_props }
                    />
                  </FormCard>
                  <FormCard>
                    <FormikInputField
                      name='max_amount'
                      type='number'
                      label='Max Amount'
                      formik_props={ formik_props }
                    />
                  </FormCard>
                </>
              }
              {
                values && values.voucher_type && values.voucher_type == 'photos' &&
                <FormCard>
                  <FormikInputField
                    name='max_photos'
                    type='number'
                    label='Max Photos'
                    formik_props={ formik_props }
                  />
                </FormCard>
              }
              <FormCard>
                <FormikInputField
                  name='code'
                  type='text'
                  label='Voucher Code'
                  formik_props={ formik_props }
                />
                <Button
                  fullWidth
                  onClick={ () => generateVoucherCode(setFieldValue) }
                  size='large'
                  color='primary'
                  variant='contained'
                >
                  Generate Voucher Code
                </Button>
              </FormCard>
              <FormCard>
                <FormikDateTimePicker
                  name='expires_at'
                  label='Expiry Date'
                  formik_props={ formik_props }
                  include_time={ false }
                />
              </FormCard>
              <FormCard>
                <FormikInputField
                  name='email'
                  type='email'
                  label='Email Address'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikDropdownField
                  name='gallery'
                  options={ galleryOptions }
                  label='Select Gallery'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikDropdownField
                  name='album'
                  options={ albumOptions }
                  label='Select Album'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikDropdownField
                  name='event'
                  options={ galleryEventOptions }
                  label='Select Event'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikDropdownField
                  name='photographer'
                  options={ photographerOptions }
                  label='Select Photographer'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikDropdownField
                  name='status'
                  options={ voucherStatusOptions }
                  label='Select Status'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <Button
                  fullWidth
                  type='submit'
                  size='large'
                  color='primary'
                  variant='contained'
                >
                  Save
                </Button>
              </FormCard>
            </Form>
          )
        }}
      </Formik>
    )
  }
}
export default AdminVoucherForm;
