import { ItemList } from "../../orm"

class AdminAutoCrop extends ItemList {

    getEntityKey() {
        return "admin/auto_crop";
    }

    autoCrop({width, height, gravity, crop, photograph}) {
        return this.saveNewObject({action: "auto_crop", photograph_id: photograph.id, width: width, height: height, gravity: gravity, crop: crop})
    }

}

export const adminAutoCrop = new AdminAutoCrop("admin_auto_crop__default");
