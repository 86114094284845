import React, { useState, useEffect } from "react"
import { get, map } from 'lodash'
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux'
import AddPrintItemButton from './AddPrintItemButton'
import AvailablePrintPaperForm from "./AvailablePrintPaperForm"
import { adminPrintPaperList } from '../../actions/print/admin_print_paper'

export default function AvailablePrintPaper({ country_print_configuration_id }) {
  const dispatch = useDispatch()
  const { formState: { errors } } = useForm()
  const printPaperOptions = useSelector(() => adminPrintPaperList.getVisibleObjects())
  const [isAddingPrintPaperOption, setIsAddingPrintPaperOption] = useState(false)

  useEffect(() => {
    async function fetchStaticObjects() {
      dispatch(adminPrintPaperList.trySetFilter({ country_print_configuration: country_print_configuration_id }))
      dispatch(adminPrintPaperList.fetchListIfNeeded())
    }
    fetchStaticObjects()
  }, [])

  const onAddPrintPaperOption = () => {
    setIsAddingPrintPaperOption(true)
  }

  const onUpdated = () => {
    setIsAddingPrintPaperOption(false)
  }

  return (
    <>
      {map(printPaperOptions, (printPaperOption) =>
        <>
          <AvailablePrintPaperForm key={get(printPaperOption, "id")} printPaperOption={printPaperOption} onUpdated={onUpdated} country_print_configuration_id={country_print_configuration_id}/>
        </>
      )}
      { isAddingPrintPaperOption && 
        <AvailablePrintPaperForm printPaperOption={null}
                                 country_print_configuration_id={country_print_configuration_id} 
                                 onUpdated={onUpdated}
        /> 
      }
      <AddPrintItemButton on_click_action={onAddPrintPaperOption}/>
    </>
  )
}