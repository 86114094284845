import { ItemList } from '../../orm'
import { get, head, size, map, filter, forEach } from 'lodash'
import moment from 'moment'
import fetch from 'isomorphic-fetch'
import { getHeaders } from '../../lib/http'
import { DEFAULT_COUNTRY_CODE, printableAwsUploadSigninUrl } from '../../actions/printable_aws_upload_signin_url'

const MAP_PLACEHOLDER_IMAGE_URL = null

class FileInfoList extends ItemList {
  getEntityKey() {
    return "file_info"
  }

  upload(file, on_done) {
    return async (dispatch, getState) => {
      return (
        dispatch(printableAwsUploadSigninUrl.getUploadUrl({file_name:file.name})).then(
          ({s3_filepath, pre_signin_res, aws_file_url}) => {
            const aws_pre_signin_url = pre_signin_res.url
            const aws_upload_img_form_data = new window.FormData()
            Object.keys(pre_signin_res.fields).forEach((key) => {
              aws_upload_img_form_data.append(key, pre_signin_res.fields[key])
            })
            aws_upload_img_form_data.append("file", file)
            let res = fetch(aws_pre_signin_url,
                            { method: "POST",
                              headers: getHeaders(null, null, {include_content_type:false}),
                              body: aws_upload_img_form_data,
                            })
                .then( (res) => {
                  if ( res.ok ) {
                    dispatch(this.saveNewObject({filename: file.name,
                                                 aws_path: s3_filepath,
                                                 country_code: DEFAULT_COUNTRY_CODE,
                                                 content_type: file.type})).then(on_done)
                  } else {
                    console.error("Upload failed", res)
                    return null
                  }
                })
                .catch(err => {
                  console.error("Upload failed", err)
                })
          })
      )}
    
  }
  
}

export const fileInfoList = new FileInfoList("file_info_list__default")
