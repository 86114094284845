import { ItemList } from "../../orm";
import { get, head, filter, lowerCase } from "lodash";

class CustomerProfileList extends ItemList {
    getEntityKey() {
      return "customer/editprofile"
    }
    
}

export const customerProfileList = new CustomerProfileList("customer_profile_default")