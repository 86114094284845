import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { withRouter } from 'react-router-dom'
import { css } from '@emotion/core'
import AdminPromotionalMediaForm from './AdminPromotionalMediaForm'
import AdminMainLayout from './AdminMainLayout'
import { adminPromotionalMediasList } from '../actions/admin_promotional_medias'

class AdminPromotionalMedia extends Component {
  componentDidMount() {
    const { dispatch, promotional_media_id } = this.props
    dispatch(adminPromotionalMediasList.invalidateList())
    dispatch(adminPromotionalMediasList.ensureObjectLoaded(promotional_media_id))
  }

  onSave = (values) => {
    const { dispatch, history, promotional_media_id } = this.props
    const on_done = function () {
      dispatch(adminPromotionalMediasList.invalidateList())
      dispatch(adminPromotionalMediasList.fetchListIfNeeded())
      history.push('/admin/promotional_medias')
    }
    if (promotional_media_id) {
      values.id = promotional_media_id
      return dispatch(adminPromotionalMediasList.saveObject(values)).then(on_done)
    } else {
      return dispatch(adminPromotionalMediasList.saveNewObject(values)).then(on_done)
    }
  }
  
  render() {
    const {promotional_media, initial_values } = this.props
    return (
      <AdminMainLayout>
        <h2>
          {`${isEmpty(initial_values) ? 'Add new' : 'Edit'}`} Promotional Media
        </h2>
        <AdminPromotionalMediaForm
          onSave={ this.onSave }
          initial_values={ initial_values }
          promotional_media={ promotional_media }
        />
      </AdminMainLayout>
    )
  }
}

function mapStateToProps(state, props) {
  const { promotional_media_id } = props.match.params 
  const promotional_media = adminPromotionalMediasList.getObject(promotional_media_id)
  const initial_values = promotional_media
  return {
    initial_values,
    promotional_media,
    promotional_media_id,
    is_busy: adminPromotionalMediasList.isLoading()
  }
}
export default withRouter(connect(mapStateToProps)(AdminPromotionalMedia))

const image = css`
  width: 80%;
`
