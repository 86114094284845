import React, { useState, useEffect } from "react"
import { size, get, map, filter, head } from "lodash"
import { useForm, useFieldArray } from "react-hook-form"
import { Button, makeStyles } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { Modal } from '../../components/layout/Modal'
import { showError, showSuccess } from '../../actions/Error'
import { adminPhotographList, adminPhotographResultList } from '../actions/admin_photographs'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import Loading from '../../components/Loading'
import { adminAutoCrop } from '../actions/admin_auto_crop'
import clsx from 'clsx'

const useStyles = makeStyles({
    container: {
    },
    photographList: {
        display: "flex",
        flexWrap: "wrap",
    },
    photographInList: {
        cursor: "pointer",
        border: "1px solid black",
        margin: "5px",
        "&:hover": {
            border: "1px solid red",
            background: "lightGray",
        },
    },
    photographRow: {
        display: "flex"
    },
    photographImgInList: {
        width: "100px",
        margin: "10px"
    },
    previewFullSize: {
        width: "1000px",
    },
    previewThumbnail: {
        width: "200px",
    },
    modalContent: {
        padding: "20px"
    }
})

export const AdminAutoCrop = ({}) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [selectedPhotograph, setSelectedPhotograph] = useState()
    const [showAutoCropForm, setShowAutoCropForm] = useState(false)
    const photographs = useSelector(() => adminPhotographList.getVisibleObjects())
    const [lastCloudinaryUrl, setLastCloudinaryUrl] = useState(null)
    const [lastResult, setLastResult] = useState(null)
    const isCropping = useSelector(() => adminAutoCrop.getIsSavingObject())
    const { handleSubmit, register } = useForm({ defaultValues: {crop: 'crop',
                                                                 gravity: 'auto',
                                                                 width: '',
                                                                 zoom: ''} })

    useEffect(() => {
        dispatch(adminPhotographList.updatePaginationNumItemsPerPage(50))
        dispatch(adminPhotographList.fetchListIfNeeded())
    }, [])

    const onAutoCropPhotograph = (values) => {
        const photograph = selectedPhotograph

        
        const on_done = (json) => {
            if ( json.status === "success" ) {
                setLastCloudinaryUrl(json.cloudinary_url)
                showSuccess("Auto Cropped")
                dispatch(adminPhotographList.invalidateObject(photograph.id))
                dispatch(adminPhotographList.ensureObjectLoaded(photograph.id))
            } else {
                showError("Failed", json.error_message)
            }
            setShowAutoCropForm(false)
        }
        
        values.photograph = photograph
        dispatch(adminAutoCrop.autoCrop(values)).then(on_done)
    }

    const onStartAutoCropPhotograph = (photograph) => {
        setSelectedPhotograph(photograph)
        setShowAutoCropForm(true)
    }

    const onStopAutoCropPhotograph = () => {
        setShowAutoCropForm(false)
    }

    const showAutoCropPhotograph = (photograph) => {
        window.open(photograph.auto_crop_gallery_size_file_info.download_url, "_blank")
    }

    const renderAutoCropForm = () => {
        return (
            <Modal title="Crop configuration"
                   onClose={onStopAutoCropPhotograph}
            >
              <div className={classes.modalContent}>

                <img className={classes.photographImgInList} src={selectedPhotograph.gallery_size_file_info.download_url} />
                
                <form onSubmit={handleSubmit(onAutoCropPhotograph)}>

                  <p>
                    <i>Any field left blank will not be passed to cloudinary</i>
                  </p>
                  
                  <div>
                    Width (pixels): <input {...register("width")} />
                  </div>
                  <br/>

                  <div>
                    Gravity:
                    <input {...register("gravity")} />
                  </div>
                  <br/>

                  <div>
                    Crop:
                    <input {...register("crop")} />
                  </div>
                  <br/>
                  

                  <div>
                    Zoom:
                    <input {...register("zoom")} />
                  </div>
                  <br/>
                  
                  <button type="submit">
                    Start cropping
                  </button>
                </form>
              </div>
            </Modal>
        )
    }

    const renderLastCloudinaryUrl = () => {
        return (
            <div>Cloudinary url: <a href={lastCloudinaryUrl} target="_blank">{lastCloudinaryUrl}</a></div>
        )
    }
    
    const renderPhotographs = () => {
        return (
            <div className={classes.photographList}>
              {map(photographs, (x) => (
                  <>
                    {get(x, "gallery_size_file_info") &&
                     <div className={classes.photographInList}>
                       <div className={classes.photographRow}>
                         <img className={classes.photographImgInList} src={x.gallery_size_file_info.download_url} />
                         { x.auto_crop_gallery_size_file_info && 
                           <img className={classes.photographImgInList} src={x.auto_crop_gallery_size_file_info.download_url} />
                         }
                       </div>
                       <Button onClick={() => onStartAutoCropPhotograph(x)}>
                         Auto crop
                       </Button>

                       { x.auto_crop_gallery_size_file_info &&
                         <>
                           <Button onClick={() => showAutoCropPhotograph(x)}>
                             View auto cropped
                           </Button>
                           <br/>
                           Cloudinary id: { x.auto_crop_gallery_size_public_id }
                         </>
                       }
                     </div>
                    }
                  </>
              ))}
            </div>
        )
    }

    return (
        <div>

          {isCropping && <Loading msg={"Cropping through Cloudinary..."}/>}

          { showAutoCropForm && renderAutoCropForm() }
          
          <h2>Test page for auto cropping</h2>
          { lastCloudinaryUrl && renderLastCloudinaryUrl() }
          
          <br/>
          
          <div>
            { renderPhotographs() }
          </div>
          
        </div>
    )
    
    
}
