import React, { useState, useEffect } from "react"
import { size, get, map, filter, head } from "lodash"
import { useForm, useFieldArray } from "react-hook-form"
import { Button, makeStyles } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { Modal } from '../../components/layout/Modal'
import { showError, showSuccess } from '../../actions/Error'
import { adminPhotographList, adminPhotographResultList } from '../actions/admin_photographs'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import Loading from '../../components/Loading'
import { adminFacialRecognition } from '../actions/admin_facial_recognition'
import clsx from 'clsx'

const useStyles = makeStyles({
    container: {
    },
    photographList: {
        display: "flex",
        flexWrap: "wrap",
    },
    photographInList: {
        cursor: "pointer",
        border: "1px solid black",
        margin: "5px",
        "&:hover": {
            border: "1px solid red",
            background: "lightGray",
        },
    },
    photographImgInList: {
        width: "100px",
        margin: "10px"
    },
    previewFullSize: {
        width: "1000px",
    },
    previewThumbnail: {
        width: "200px",
    },
    modalContent: {
        padding: "20px"
    }
})

export const AdminFacialRecognition = ({}) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [selectedPhotograph, setSelectedPhotograph] = useState()
    const photographs = useSelector(() => adminPhotographList.getVisibleObjects())
    const [capturingCustomerEmailForIndexing, setCapturingCustomerEmailForIndexing] = useState(false)
    const [capturingCustomerEmailForSearching, setCapturingCustomerEmailForSearching] = useState(false)
    const [matchedPhotographIds, setMatchedPhotographIds] = useState(false)
    const matchedPhotographs = useSelector(() => adminPhotographResultList.getObjects(matchedPhotographIds))
    const [showMatchedPhotographs, setShowMatchedPhotographs] = useState(false)
    const [lastResult, setLastResult] = useState(null)

    const isSaving = useSelector(() => adminFacialRecognition.getIsSavingObject())
    const { handleSubmit, register } = useForm({ defaultValues: {email: null} })

    useEffect(() => {
        dispatch(adminPhotographList.updatePaginationNumItemsPerPage(50))
        dispatch(adminPhotographList.updateListFilter({country_code: 'au'}))
        dispatch(adminPhotographList.fetchListIfNeeded())
    }, [])

    useEffect(() => {
        dispatch(adminPhotographResultList.ensureObjectsLoaded(matchedPhotographIds))
    }, [matchedPhotographIds])

    const onIndexFacesPhotograph = (photograph) => {
        setSelectedPhotograph(photograph)

        const on_done = (json) => {
            if ( json.status === "success" ) {
                showSuccess("Indexed Faces", JSON.stringify(json.face_tokens))
                setLastResult("FaceIds : " + JSON.stringify(json.face_tokens))
            } else {
                showError("Failed", json.error_message)
            }
        }
        
        dispatch(adminFacialRecognition.indexFaces({photograph})).then(on_done)
    }
    
    const onStartIndexingAsCustomer = (photograph) => {
        setSelectedPhotograph(photograph)
        setCapturingCustomerEmailForIndexing(true)
    }

    const onStopIndexingAsCustomer = () => {
        setSelectedPhotograph(null)
        setCapturingCustomerEmailForIndexing(false)
    }

    const onIndexCustomer = (values) => {
        const { customer_email } = values
        onStopIndexingAsCustomer()

        const on_done = (json) => {
            if ( json.status === "success" ) {
                showSuccess("Indexed Customer", json.face_token)
                setLastResult("Customer FaceId : " + JSON.stringify(json.face_token))
            } else {
                showError("Failed", json.error_message)
            }
        }
       
        dispatch(adminFacialRecognition.indexForCustomer({photograph: selectedPhotograph, customer_email})).then(on_done)
    }

    const onStartSearchingForCustomer = () => {
        setCapturingCustomerEmailForSearching(true)
    }

    const onStopSearchingForCustomer = () => {
        setCapturingCustomerEmailForSearching(false)
    }

    const onSearchCustomer = (values) => {
        const { customer_email } = values

        const on_done = (json) => {
            if ( json.status === "success" ) {
                setMatchedPhotographIds(json.matching_photograph_ids)
                setShowMatchedPhotographs(true)
                showSuccess("Found photographs")
            } else {
                showError("Failed", json.error_message)
            }
        }
        
        dispatch(adminFacialRecognition.searchForCustomer({customer_email})).then(on_done)
        onStopSearchingForCustomer()
    }

    const renderMatchedPhotographs = () => {
        return (
            <Modal title="Matched photographs"
                   onClose={() => setShowMatchedPhotographs(false)}
                   fullScreen
            >
              <div className={classes.modalContent}>
                <div className={classes.photographList}>
                  {map(matchedPhotographs, (x) => (
                      <>
                        {get(x, "gallery_size_file_info") &&
                         <div className={classes.photographInList}>
                           <img className={classes.photographImgInList} src={x.gallery_size_file_info.download_url} />
                         </div>
                        }
                      </>
                  ))}
                </div>
              </div>
            </Modal>
        )
    }

    const renderPhotographs = () => {
        return (
            <div className={classes.photographList}>
              {map(photographs, (x) => (
                  <>
                    {get(x, "gallery_size_file_info") &&
                     <div className={classes.photographInList}>
                       <img className={classes.photographImgInList} src={x.gallery_size_file_info.download_url} />
                       <Button onClick={() => onIndexFacesPhotograph(x)}>
                         Index faces
                       </Button>
                       <Button onClick={() => onStartIndexingAsCustomer(x)}>
                         Index customer
                       </Button>
                     </div>
                    }
                  </>
              ))}
            </div>
        )
    }

    const renderCaptureCustomerEmailForIndexing = () => {
        return (
            <Modal title="Customer Email"
                   onClose={onStopIndexingAsCustomer}
            >
              <div className={classes.modalContent}>
                <form onSubmit={handleSubmit(onIndexCustomer)}>
                  Customer email: <input {...register("customer_email")} />
                  <button type="submit">
                    Index Customer
                  </button>
                </form>
              </div>
            </Modal>
        )
    }
    
    const renderCaptureCustomerEmailForSearching = () => {
        return (
            <Modal title="Customer Email"
                   onClose={onStopSearchingForCustomer}
            >
              <div className={classes.modalContent}>
                <form onSubmit={handleSubmit(onSearchCustomer)}>
                  Customer email: <input {...register("customer_email")} />
                  <button type="submit">
                    Search for Customer
                  </button>
                </form>
              </div>
            </Modal>
        )
    }

    const renderLastResult = () => {
        return (
            <div>
              {lastResult}
            </div>
        )
    }
    
    return (
        <div>

          {isSaving && <Loading msg={"Calculating..."}/>}
          
          <h2>Test page for facial recognition</h2>
          <h3>Note: Africa does not support the AWS Rekognition API yet, only AU photos are shown here</h3>
          

          <Button onClick={onStartSearchingForCustomer}>
            Search for previously indexed customer
          </Button>

          { size(matchedPhotographIds) > 0 &&
            <Button onClick={() => setShowMatchedPhotographs(true)}>
              Show last matched photos
            </Button>
          }
          { lastResult && renderLastResult() }
          
          <br/>
          
          <div>
            { renderPhotographs() }
          </div>
          
          { capturingCustomerEmailForIndexing && renderCaptureCustomerEmailForIndexing() }
          { capturingCustomerEmailForSearching && renderCaptureCustomerEmailForSearching() }
          { showMatchedPhotographs && renderMatchedPhotographs() }
          
        </div>
    )
    
    
}
