import React, { Component } from 'react'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { Formik, Form  } from 'formik'
import FormCard from '../../components/layout/FormCard'
import * as Yup from 'yup'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Button } from '@material-ui/core'

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
})

class AdminAlbumForm extends Component {


  render() {
    const {
      onSave,
      album,
      initial_values,
      galleryEventOptions
    } = this.props


    return (
      <Formik
        enableReinitialize
        onSubmit={ onSave }
        initialValues={ initial_values }
        validationSchema={ validationSchema }
      >
        {(formik_props) => {
          const { values } = formik_props
          return (
            <Form>
              <FormCard>
                <FormikInputField
                  name='name'
                  type='text'
                  label='Name'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikInputField
                  type='text'
                  name='public_url'
                  label='Public Url Path'
                  placeholder='bossfotos'
                  helperText='URL path name only (e.g. surfingschool) | exclude https://www.bossfotos.com/ '
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikInputField
                  type='text'
                  placeholder='bossfotos'
                  name='direct_access_url'
                  label='Direct Access Url Path'
                  helperText='URL path name only (e.g. bossfotos_highschool) | exlude https://www.bossfotos.com/ '
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikCheckboxField
                  name='enabled'
                  type='checkbox'
                  checked={ values.enabled }
                  label='Enable Album'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikDropdownField
                  name='gallery_event'
                  options={galleryEventOptions}
                  label='Select Gallery Event'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <Button
                  fullWidth
                  type='submit'
                  size='large'
                  color='primary'
                  variant='contained'
                >
                  Save
                </Button>
              </FormCard>
            </Form>
          )
        }}
      </Formik>
    )
  }
}
export default AdminAlbumForm;
