import React, { useState, useEffect } from "react"
import { get, map, filter, head } from "lodash"
import { Button, makeStyles } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { adminImageWithCutoutList } from '../../actions/admin_image_with_cutouts'
import { cutoutImageList } from '../../../actions/cutout_image'
import clsx from 'clsx'

const useStyles = makeStyles({
    baseContainer: {
        position: "relative",
    },
    baseCutoutImage: {
    },
    baseFittedImage: {
        position: "absolute",
        top: "0px",
        left: "0px",
    },
    loadingImage: {
        position: "absolute",
        top: "0px",
        left: "0px",
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        opacity: 0.3,
        fontSize: "30px",
        fontStyle: "bold"
    },
})

export const PreviewImageWithCutout = ({imageWithCutoutId, photograph, onClose, imageClass}) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const imageWithCutout = useSelector(() => imageWithCutoutId && adminImageWithCutoutList.getObject(imageWithCutoutId))
    const cutoutImage = useSelector(() => cutoutImageList.getCutoutImage({imageWithCutout, photograph}))
    const isLoadingCutout = useSelector(() => cutoutImageList.isLoading())

    useEffect(() => {
        if ( imageWithCutoutId ) {
            dispatch(adminImageWithCutoutList.ensureObjectLoaded(imageWithCutoutId))
        }
    }, [imageWithCutoutId])

    useEffect(() => {
        dispatch(cutoutImageList.ensureCutoutImageLoaded({imageWithCutout, photograph}))
    }, [get(photograph, "id")])

    return (
        <div className={clsx(classes.baseContainer, imageClass)}>
          <img className={clsx(classes.baseCutoutImage, imageClass)} src={get(imageWithCutout, ["image_file_info", "download_url"])} />
          <>
            { cutoutImage && 
              <img className={clsx(classes.baseFittedImage, imageClass)} src={get(cutoutImage, ["fitted_image_file_info", "download_url"])} />
            }
          </>

          {photograph && isLoadingCutout &&
           <div className={clsx(classes.loadingImage)}>
             Processing...
           </div>
          }
          
        </div>
    )
    
    
}
