import React from "react"
import { Button } from '@material-ui/core'
import { useForm } from "react-hook-form"
import { get, map } from 'lodash'
import { useDispatch } from 'react-redux'
import SavePrintItemButton from './SavePrintItemButton'
import { adminPrintPaperList, adminPrintPaperOptionsList } from '../../actions/print/admin_print_paper'

export default function AvailablePrintPaperForm({ printPaperOption, country_print_configuration_id, onUpdated }) {
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm({
    defaultValues: { 
      name: get(printPaperOption, "name"),
      enabled: get(printPaperOption, "enabled") 
    }
  })
  
  const onSubmit = (values) => {
    values.country_print_configuration = country_print_configuration_id

    const on_done = function () {
      dispatch(adminPrintPaperList.invalidateList())
      dispatch(adminPrintPaperList.fetchListIfNeeded())
      dispatch(adminPrintPaperOptionsList.invalidateList())
      dispatch(adminPrintPaperOptionsList.fetchListIfNeeded())
      onUpdated()
    }
    
    if (printPaperOption) {
      values.id = printPaperOption.id
      return dispatch(adminPrintPaperList.saveObject(values)).then(on_done)
    }else {
      return dispatch(adminPrintPaperList.saveNewObject(values)).then(on_done)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <input 
            type="checkbox"
            {...register("enabled")} 
            defaultChecked={printPaperOption === null ? true : false}
            />
         <input
            {...register("name")} 
            />
        <Button type="submit">
            <SavePrintItemButton/>
        </Button>
    </form>
  )
}