import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { get, map, filter, head } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { Button, makeStyles } from "@material-ui/core"
import MDEditor, { commands } from "@uiw/react-md-editor"
import ReactMarkdown from "react-markdown"
import { ImageWithCutoutForm } from "./ImageWithCutoutForm"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import SavePrintItemButton from "./SavePrintItemButton"
import { PreviewImageWithCutoutModal } from './PreviewImageWithCutoutModal'
import { showError, showSuccess } from '../../../actions/Error'
import { adminImageWithCutoutList } from '../../actions/admin_image_with_cutouts'
import { adminPrintTypeOptionsList, adminPrintTypesList } from "../../actions/print/admin_print_types"

const useStyles = makeStyles({
    txt_center: {
        textAlign: "center"
    },
    printTypeImage: {
        width: "200px",
        cursor: "pointer",
    },
    cancelFileUploadButton: {
        position: "relative",
        zIndex: "99999"
    }
})

export default function AvailablePrintTypeForm({ printType, country_print_configuration_id, onUpdated }) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const defaultValues = { 
        name: get(printType, "name"),
        description: get(printType, "description"),
        images: get(printType, "images"),
        enabled: get(printType, "enabled") 
    }
    const formProps = useForm({ defaultValues: defaultValues })
    const { handleSubmit, control, clearErrors, reset, register, getValues, setValue, formState: { errors } } = formProps
    const [isAddingImage, setIsAddingImage] = useState(false)
    const [editingImageId, setEditingImageId] = useState(null)
    const [previewImageId, setPreviewImageId] = useState(null)
    const imageWithCutouts = useSelector(() => printType && adminImageWithCutoutList.getObjects(getValues("images")))
    const [showPrintTypeDescriptionEditor, setShowPrintTypeDescriptionEditor] = useState(false)
    const [printTypeDescription, setPrintTypeDescription] = useState(get(printType, "description"))

  useEffect(() => {
    if ( printType ) {
      dispatch(adminImageWithCutoutList.ensureObjectsLoaded(printType.images))
    }
  }, [get(printType, "id")])

  const onStartEditingImage = (image_id) => {
    setEditingImageId(image_id)
  }

  const onStopEditingImage = () => {
    setEditingImageId(null)
    setIsAddingImage(false)
  }

  const onStartPreviewImage = (image_id) => {
    setPreviewImageId(image_id)
  }

  const onStopPreviewImage = () => {
    setPreviewImageId(null)
  }

  const onDeletePreviewImage = (image_id) => {
    dispatch(adminImageWithCutoutList.deleteObject(image_id))
    dispatch(adminPrintTypesList.invalidateList())
    dispatch(adminPrintTypesList.fetchListIfNeeded())
    reset(defaultValues)
  }

  const onImageUpdated = ({imageWithCutout}) => {
    if ( isAddingImage ) {
      const images = getValues("images")
      images.push(imageWithCutout.id)
      setValue("images", images)
      setIsAddingImage(false)
    } else {        
      onStopEditingImage()
    }
  }

  const onEditPrintOptionDescription = () => {
    setShowPrintTypeDescriptionEditor(true)
  }

  const closePrintOptionDescriptionEditor = () => {
    setShowPrintTypeDescriptionEditor(false)
  }
  
  const onSubmit = (values) => {
    values.country_print_configuration = country_print_configuration_id

    const on_done = function (json) {
      if (! json.id ) {
        showError("Failed to save")
        console.error(json)
      } else {
        dispatch(adminPrintTypesList.invalidateList())
        dispatch(adminPrintTypesList.fetchListIfNeeded())
        dispatch(adminPrintTypeOptionsList.invalidateList())
        dispatch(adminPrintTypeOptionsList.fetchListIfNeeded())
        onUpdated()
        showSuccess("Saved")
      }
    }
    
    if (printType) {
      values.id = printType.id
      return dispatch(adminPrintTypesList.saveObject(values)).then(on_done)
    }else {
      return dispatch(adminPrintTypesList.saveNewObject(values)).then(on_done)
    }
  }

  const savePrintOptionDescriptionEditor = () => {
    setValue("description", printTypeDescription)
    closePrintOptionDescriptionEditor()
  }

  const setFormImageValue = (file) => {
    setValue("image", file.id)
  }

  const renderPrintTypeDescriptionEditor = () => {
    return (
      <Modal open={showPrintTypeDescriptionEditor}
             onClose={closePrintOptionDescriptionEditor}
             fullScreen
      >
        <div className={classes.modalContainer}>
          <Typography>Markdown editor</Typography>
          <Button
            type="button"
            onClick={() => savePrintOptionDescriptionEditor()}
          >
            Save
          </Button>
          <Button
            type="button"
            onClick={() => closePrintOptionDescriptionEditor()}
          >
            Cancel
          </Button>
          <Box>
            <MDEditor
              commands={[
                commands.group([], {
                  name: "update",
                  groupName: "update",
                  buttonProps: { "aria-label": "Insert title" },
                }),
              ]}

              {...register("description")}
              value={printTypeDescription}
              onChange={setPrintTypeDescription}
            />
          </Box>
        </div>
      </Modal>
    )
  }
  
  const renderImageFields = () => {

    return (
      <>
        { map(getValues("images"), function(image_id, index) {
          const imageWithCutout = head(filter(imageWithCutouts, (x) => x.id === image_id))
          return (
            <div>
              <div>Image #{index+1}</div>
              <img className={classes.printTypeImage} src={get(imageWithCutout, ["image_file_info", "download_url"])} />

              { editingImageId === get(imageWithCutout, "id") && 
                <ImageWithCutoutForm imageWithCutoutId={editingImageId} onUpdated={onImageUpdated} onCancel={onStopEditingImage} />
              }
              { previewImageId === get(imageWithCutout, "id") &&
                <PreviewImageWithCutoutModal imageWithCutoutId={previewImageId} onClose={onStopPreviewImage} />
              }
              <Button onClick={() => onStartEditingImage(get(imageWithCutout, "id"))}>Edit</Button>
              <Button onClick={() => onStartPreviewImage(get(imageWithCutout, "id"))}>Preview</Button>
              <Button onClick={() => onDeletePreviewImage(get(imageWithCutout, "id"))}>Delete</Button>
            </div>
          )
        })}

        { get(printType, "id") && 
          <Button onClick={setIsAddingImage}>Add image</Button>
        }
        { !get(printType, "id") &&
          <div>Save first before adding images</div>
        }
        
        { isAddingImage &&
          <ImageWithCutoutForm imageWithCutoutId={null} onUpdated={onImageUpdated} onCancel={onStopEditingImage} />
        }
      </>
    )
    
  }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <table>
        <thead>
          <tr>
            <th>Enabled</th>
            <th>Name</th>
            <th>Image</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input type="checkbox" defaultChecked={printType === null} {...register("enabled")}/>
            </td>
            <td>
              <input {...register("name")}/>
            </td>
            <td className={classes.txt_center}>
              { renderImageFields() }
            </td>
            <td>
              <Box className={classes.markdownPreview}>
                <ReactMarkdown>{getValues("description")}</ReactMarkdown>
              </Box>
              <Button onClick={onEditPrintOptionDescription}>
                Edit
              </Button>
            </td>
            <td>
              <Button type="submit">
                <SavePrintItemButton/>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      {showPrintTypeDescriptionEditor && renderPrintTypeDescriptionEditor()}
    </form>
  )
}