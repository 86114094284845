import { ItemList } from "../../orm";

class AdminAlbumsList extends ItemList {

  getEntityKey() {
    return "admin/album";
  }

}

export const adminAlbumsList = new AdminAlbumsList("admin_albums__default");
