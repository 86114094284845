import React from 'react'
import { Button } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FormikInputField } from './form/InputField'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import { Copyright }  from './Copyright'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, FieldArray, Field } from 'formik'
import { useDispatch } from 'react-redux'
import { login } from '../actions/auth'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    logo: {
      width: 200,
      marginTop: 50,
      [theme.breakpoints.down('sm')]: {
        width: 150,
        marginTop: 30
      },
      display: 'block',
    },
    lockIcon: {
      fontSize: 40,
      marginBottom: 12,
      marginTop: 12
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: 'auto',
        width: 400
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontSize:24
    },
    //style for font size
    resize:{
      fontSize:30
    },
    loginCopyright: {
      position: 'absolute',
      left:0,
      right:0,
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('md')]: {
        bottom: 10,
        left: 10,
      },
      [theme.breakpoints.down('sm')]: {
        bottom: 40,
        left: 15,
      }
    },
    loginBlock: {
      position: 'relative',
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10
      }
    }
}))

export default function Login({renderForm, renderOutsideForm, onSubmit, validationSchema, initialValues}) {
    const classes = useStyles()

    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} md={6} lg={8} className={classes.image} />

        <Grid item xs={12} md={6} lg={4} className={classes.loginBlock}>

          <Grid container justify="center" alignContent="center" align="center">
            <Grid item xs={12} lg={8}>

              <LockOutlinedIcon className={classes.lockIcon} />

              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                {formik_props => {
                    const { values } = formik_props
                    return (
                        <Form className={classes.form}>
                          { renderForm({formik_props, classes}) }
                        </Form>
                    )}
                }
              </Formik>
              <div style={{float: 'right'}}>{renderOutsideForm}</div>
              <div className={classes.loginCopyright}>
                  <Copyright />
              </div>
            </Grid>
          </Grid>




        </Grid>

      </Grid>
    )
}
