import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { map, head, get } from 'lodash'
import {Modal, makeStyles, Dialog, AppBar,Toolbar,IconButton} from '@material-ui/core'
import AdminCommonListLayout from './AdminCommonListLayout'
import { adminLedgerTransactionsList } from '../actions/admin_ledger_transactions'
import { countryList } from '../actions/admin_countries'
import { customerProfileList } from '../actions/customer_profile'
import { adminBillingAddressList } from '../actions/admin_billing_address'
import Timestamp from '../../components/Timestamp'

const useStyles = makeStyles((theme) => ({
  purchased_trolley_photo: {
    width: "200px"
  },
  ledgerTransactionModalWrapper: {
    paddingTop: "60px"
  }
  
}))

export const AdminLedgerTransactions = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const is_loading = useSelector(() => adminLedgerTransactionsList.isLoading())
  const [openLedgerTransactionModal, setOpenLedgerTransactionModal] = useState(false)
  const [activeLedgerTransactionId, setActiveLedgerTransactionId] = useState(null)
  const ledgerTransaction = useSelector(() => adminLedgerTransactionsList.getLedgerTransactionById(activeLedgerTransactionId))
  const customer_billing_address = useSelector(() => head(adminBillingAddressList.getVisibleObjects()))
  const purchased_trolley_photographs = get(ledgerTransaction, ["purchased_trolley", "purchased_trolley_photographs"])
  const customer_internal_id = get(ledgerTransaction, "customer_internal_id")
  const customer_billing_country = useSelector(() => countryList.getCountryForId(get(customer_billing_address, "country")))


  const columns = [
    { field: 'id', title: ' Id',
      render: (item) => get(item, "primary_key"),
      sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'ref' : '-ref')
    },
    { field: 'customer_email', title: 'Customer email',
      render: (item) => get(item, ["customer_profile", "email"]),
      sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'customer_email' : '-customer_email')
    },
    { field: 'photographer_first_name', title: 'Photographer name'},
    { field: 'photographer_email', title: 'Photographer email',
      sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'photographer__email' : '-photographer_email')
    },
    {
        field: 'bank_credit',
        title: 'Bank credit'
    },
    { field: 'created_at', title: 'Created date',
      sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'created_at' : '-created_at'),
      render: (item) => <div>
                          <Timestamp value={get(item, "created_at")} format="dateshort-time" />
                        </div>,
    },
    {
      field:  "detail",
      title:  "Details",
      render: (item) => <div>
                          <button onClick={() => onExpandLedgerTransaction(get(item, "id"))}>
                            Details
                          </button>
                        </div>,
    }
  ]

  useEffect(() => {
    async function fetchStaticObjects() {
      dispatch(adminLedgerTransactionsList.fetchListIfNeeded())
      dispatch(adminBillingAddressList.trySetFilter({ customer: get(ledgerTransaction, ["customer_profile", "id"]) }))
      dispatch(adminBillingAddressList.fetchListIfNeeded())
      dispatch(countryList.fetchListIfNeeded())
      dispatch(customerProfileList.fetchListIfNeeded())
    }
    
    fetchStaticObjects()
  }, [activeLedgerTransactionId, get(ledgerTransaction, ["customer_profile", "id"])])

  useEffect(() => {
    setInterval(() => {
      dispatch(adminLedgerTransactionsList.invalidateList())
      dispatch(adminLedgerTransactionsList.fetchListIfNeeded())
    },1800000)
  }, [])
    
  const onUpdateListOrdering = (field) => {
    dispatch(adminLedgerTransactionsList.updateListOrdering(field))
    dispatch(adminLedgerTransactionsList.fetchListIfNeeded())
  }

  const onExpandLedgerTransaction = (ledger_transaction_id) => {
    setOpenLedgerTransactionModal(true)
    setActiveLedgerTransactionId(ledger_transaction_id)
  }

  const onCollapseLedgerTransactionModal = () => {
    setOpenLedgerTransactionModal(false)
  }
  
  const renderLedgerTransactionModal = () => {
    return (
      <div>
        <Dialog fullScreen open onClose={onCollapseLedgerTransactionModal}>
          <AppBar>
          <Toolbar>
            <IconButton onClick={onCollapseLedgerTransactionModal}>
              Close
            </IconButton>
          </Toolbar>
        </AppBar>
            <div className={classes.ledgerTransactionModalWrapper}>
              <p>Created Date: 
                <Timestamp value={get(ledgerTransaction, "created_at")} format="dateshort-time" />
              </p>
              <p>Ledger Transaction ID: {get(ledgerTransaction, "primary_key")}</p>
              <p>Ledger Transaction Expense: {get(ledgerTransaction, "expense")}</p>
              <h2>Customer Details</h2>
              <p>User Id: {get(ledgerTransaction, "customer_internal_id")}</p>
              <p>First Name: {get(ledgerTransaction, ["customer_profile", "first_name",])}</p>
              <p>Last Name: {get(ledgerTransaction, ["customer_profile", "last_name",])}</p>
              <p>Email: {get(ledgerTransaction, ["customer_profile", "email",])}</p>
              <h4>Customer Billing Details</h4>
              <p>Contact Name: {get(customer_billing_address, "contact_name")}</p>
              <p>Contact Number: {get(customer_billing_address, "contact_number")}</p>
              <p>Country: {get(customer_billing_country, "country_name")}</p>
              <p>Province: {get(customer_billing_address, "province")}</p>
              <p>Town/City: {get(customer_billing_address, "town")}</p>
              <p>Street Address One: {get(customer_billing_address, "street_address_one")}</p>
              <p>Street Address Two: {get(customer_billing_address, "street_address_two")}</p>
              <p>Postal Code: {get(customer_billing_address, "postal_code")}</p>
              <hr/>
              {map(purchased_trolley_photographs, (purchased_trolley_photograph) =>
                <>
                  <div>
                    <p>Photograph id: {purchased_trolley_photograph.id}</p>
                    <p>Photograph Name: {purchased_trolley_photograph.printable_photograph_visible_filename}</p>
                    <img className={classes.purchased_trolley_photo} src={get(purchased_trolley_photograph, ["photograph", "gallery_size_file_info", "download_url"])}/>
                    <p>Photo delivery type: {purchased_trolley_photograph.photo_delivery_type}</p>
                    <p>Print Type:  {get(purchased_trolley_photograph, ["print_price_config", "print_type", "name"])}</p>
                    <p>Print Size:  {get(purchased_trolley_photograph, ["print_price_config", "print_size", "name"])}</p>
                    <p>Print Frame: {get(purchased_trolley_photograph, ["print_price_config", "print_frame", "name"])}</p>
                    <p>Print Paper: {get(purchased_trolley_photograph, ["print_price_config", "print_paper", "name"])}</p>
                    <p>Print Supplier: {get(purchased_trolley_photograph, ["print_price_config", "print_supplier"])}</p>
                    <p>Printable Size File Info: <a href={get(purchased_trolley_photograph, ["printable_size_file_info", "download_url"])}>Download Printable photo</a></p>
                  </div>
                </>
              )}
            </div>
          </Dialog>
      </div>
    )
  }
  
  return (
    <>
      <AdminCommonListLayout active_key="ledger_transactions"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'ledger_transactions', label: "Ledger Transactions", url: '/admin/ledger_transactions'}]}
                             is_loading={is_loading}
                             columns={columns}
                             item_list={adminLedgerTransactionsList}
      />
      {openLedgerTransactionModal && renderLedgerTransactionModal()}  
    </>
  )
}
