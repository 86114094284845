import { ItemList } from '../../orm'
import { get } from 'lodash'

class AdminPromotionalMediasList extends ItemList {

  getEntityKey() {
    return 'admin/promotional_media'
  }

}

export const adminPromotionalMediasList = new AdminPromotionalMediasList('admin_promotional_medias__default')