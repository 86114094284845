import React, {useEffect, useState} from 'react'
import { useDropzone } from 'react-dropzone'
import {size, map, get, isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import DoneIcon from '@material-ui/icons/Done'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import Loading from '../Loading'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import TimerIcon from '@material-ui/icons/Timer'
import AttachFileIcon from "@material-ui/icons/AttachFile"
import ErrorIcon from '@material-ui/icons/Error'
import { Tooltip, makeStyles, } from "@material-ui/core"
// import loading_image from '../../assets/img/loading.gif'
import { FormButton } from './FormButton'
import { fileInfoList } from '../../admin/actions/admin_file_info'

const useStyles = makeStyles({
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
        maxHeight: "125px",
        overflow: "auto"
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
        position: "relative"
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%'
    },
    file_annotation_style: {
        position: "absolute",
        top: "10px",
        left: "10px",
        background: "white"
    },
    file_loading_style: {
        position: "absolute",
        right: "10px",
        bottom: "10px",
    },
    file_icon: {
        transform: "rotate(308deg)",
        color:'black'
    },
    dropzone: {
        textAlign:'center',        
    },
    flexBtn:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between ',
        width:'300px',
        paddingLeft:'5px',
        paddingRight:'5px',
        paddingTop:'15px',
        paddingBottom:'15px',
      },
      flex1:{
        position:'absolute', 
        left:'2%'
      },
      flex2:{
          position:'absolute',
          left:'15%',
          width:'75%',
          textTransform:'none',
          marginRight:'0px'
      }
})

export const FileUploader = ({onChange, onSave, fileAnnotations}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [files, setFiles] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles)

            setIsLoading(true)
            
            dispatch(fileInfoList.upload(acceptedFiles[0], (file_info) => {
                onChange(file_info)
                setIsLoading(false)
            }))
        }
    })

    return (
        <FormButton isValueSet={ !isEmpty(files) }>
          <section className="container">

            { isLoading && <Loading msg="Uploading" /> }

            { files && <div>{files[0].name}</div> }
            
            <div {...getRootProps({className: classes.dropzone})}>
              <input {...getInputProps()} />
              <p className={classes.flexBtn}>
                <div className={classes.flex1}>
                  <AttachFileIcon className={classes.file_icon} />
                </div>
                <span className={classes.flex2}>Click to select images to upload</span>
              </p>
            </div>

          </section>

        </FormButton>
    )
}
