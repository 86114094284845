import { ItemList } from "../../../orm"

class AdminPrintTypesList extends ItemList {

  getEntityKey() {
    return "admin/print_type";
  }

  trySetFilter(newFilter) {
    return async (dispatch) => {
        const existingFilter = this.getFilter()
        if ( JSON.stringify(existingFilter) === JSON.stringify(newFilter) ) {
            return
        }
        dispatch(this.updateListFilter(newFilter))
        dispatch(this.updatePaginationNumItemsPerPage(20))
        dispatch(this.fetchListIfNeeded())
    }
}

}

export const adminPrintTypesList = new AdminPrintTypesList("admin_print_type__default")
export const adminPrintTypeOptionsList = new AdminPrintTypesList("admin_print_type_options__default")