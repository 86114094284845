import { ItemList } from "../../../orm"

class AdminPrintSizesList extends ItemList {

  getEntityKey() {
    return "admin/print_size";
  }

  trySetFilter(newFilter) {
    return async (dispatch) => {
        const existingFilter = this.getFilter()
        if ( JSON.stringify(existingFilter) === JSON.stringify(newFilter) ) {
            return
        }
        dispatch(this.updateListFilter(newFilter))
        dispatch(this.updatePaginationNumItemsPerPage(20))
        dispatch(this.fetchListIfNeeded())
    }
}

}

export const adminPrintSizesList = new AdminPrintSizesList("admin_print_size__default")
export const adminPrintSizeOptionsList = new AdminPrintSizesList("admin_print_size_options__default")