import React from "react"
import { countryList } from "../../actions/admin_countries"
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  configurationCountryText: {
      fontSize: "20px",
  }
}))

export default function AdminPrintHeader({ country_print_configuration_id }) {
  const classes = useStyles()
  const country_name = countryList.getCountryNameForCountryPrintConfigurationId(country_print_configuration_id)
  

  return (
    <div>
      <h1>BossFotos header</h1>
      <table>
        <thead>
          <tr className={classes.configurationCountryText}>
            <td><p>Configuration for Country:</p></td>
            <td><p>{ country_name }</p></td>
          </tr>
        </thead>
      </table>
    </div>
  )
}
