import { ItemList } from "../../orm"

class AdminGenreTagsList extends ItemList {

  getEntityKey() {
    return "admin/genre_tag";
  }

}

export const adminGenreTagsList = new AdminGenreTagsList("admin_genre_tags__default");