import { ItemList } from "../../orm"

class AdminPhotographList extends ItemList {

  getEntityKey() {
    return "admin/photograph"
  }

}

export const adminPhotographList = new AdminPhotographList("admin_photographs__default")
export const adminPhotographResultList = new AdminPhotographList("admin_photograph_result__default")
