import React, { Component } from 'react'
import { FormikInputField } from '../../components/form/InputField'
import { FileUploader } from '../../components/form/FileUploader'
import { Formik, Form  } from 'formik'
import { get } from 'lodash'
import FormCard from '../../components/layout/FormCard'
import * as Yup from 'yup'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Button } from '@material-ui/core'

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
})

class AdminGalleryEventForm extends Component {
    
  render() {
    const {
      onSave,
      gallery_event,
      initial_values
    } = this.props

    const event_image_url = get(gallery_event, ['event_photograph', 'gallery_size_file_info', 'download_url'])
    const onFileUploaded = (formik_props, file_info) => {
      formik_props.setFieldValue('event_photograph_file_info_for_saving', file_info)
    }
      
    return (
      <Formik
        enableReinitialize
        onSubmit={ onSave }
        initialValues={ initial_values }
        validationSchema={ validationSchema }
      >
        {(formik_props) => {
          const { values } = formik_props
          return (
            <Form>
              <FormCard>
                <FormikInputField
                  name='name'
                  type='text'
                  label='Name'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikInputField
                  type='text'
                  name='public_url'
                  label='Public Url Path'
                  placeholder='bossfotos'
                  helperText='URL path name only (e.g. surfingschool) | exclude https://www.bossfotos.com/ '
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikInputField
                  type='text'
                  placeholder='bossfotos'
                  name='direct_access_url'
                  label='Direct Access Url Path'
                  helperText='URL path name only (e.g. bossfotos_highschool) | exlude https://www.bossfotos.com/ '
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FormikCheckboxField
                  name='enabled'
                  type='checkbox'
                  checked={ values.enabled }
                  label='Enable Gallery Event'
                  formik_props={ formik_props }
                />
              </FormCard>
              <FormCard>
                <FileUploader
                  label='Image'
                  formik_props={ formik_props }
                  name='event_photograph_file_info_for_saving'
                  onChange={ (file_info) => onFileUploaded(formik_props, file_info) } 
                />
                {event_image_url && (
                  <div>
                    <h3>
                      Current image:
                    </h3>
                    <div>
                      <img src={ event_image_url }/>
                    </div>
                  </div>
                )} 
              </FormCard>
              <FormCard>
                <Button
                  fullWidth
                  type='submit'
                  size='large'
                  color='primary'
                  variant='contained'
                >
                  Save
                </Button>
              </FormCard>
            </Form>
          )
        }}
      </Formik>
    )
  }
}
export default AdminGalleryEventForm;
