import React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import ConfirmationModal from '../../components/ConfirmationModal'
import Breadcrumbs from '../../components/layout/Breadcrumbs'
import { Typography, makeStyles } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CssBaseline from '@material-ui/core/CssBaseline'
import muiTheme from '../../muiTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { Separator } from '../../components/layout/Separator'
import { Copyright } from '../../components/Copyright'
import { AdminMenu } from './AdminMenu'
import Hidden from '@material-ui/core/Hidden'
import { isLoggedIn, loggedInUser } from '../../actions/auth'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: 15,
    paddingRight: 15,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 7,
      paddingRight: 7,
    },
  }
}))

export default function AdminMainLayout({ title, children, breadcrumbs, active_key }) {
  const classes = useStyles()
  const user = loggedInUser()

  return (
      <ThemeProvider theme={muiTheme}>
        <div style={{ minHeight: "100vh" }}>
          <CssBaseline />
          <AdminMenu />

          <div className={classes.content}>
            {children}
          </div>
          
        </div>

      </ThemeProvider>
  )
}
