import React, { useEffect } from "react"
import { get } from 'lodash'
import { useHistory }  from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from "react-redux"
import AdminCommonListLayout from "./AdminCommonListLayout"
import { adminCurrencyConversionList } from "../actions/admin_currency_conversion"


export const AdminCurrencyConversions = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const is_loading = useSelector(() => adminCurrencyConversionList.isLoading())

    const columns = [
        {
            field: "country",
            title: "Country Name",
            render: (item) => <div>{get(item, "country_name")}</div>
        },
        {
            field: "conversion_rate_to_pound",
            title: "Conversion Rate to pound",
        },
        {
          field:  "edit",
          title:  "Edit",
          render: (item) => <div><button onClick={() => onEditCurrencyConversion(get(item, "id"))}>Edit</button></div>,
        },
    ]

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminCurrencyConversionList.fetchListIfNeeded())
        }
        fetchStaticObjects();
    }, [])

    const onAddCurrencyConversion = () => {
        history.push("/admin/currency_conversion/")
    }

    const onEditCurrencyConversion = (currency_conversion_id) => {
       history.push(`/admin/currency_conversion/${currency_conversion_id}`)
    }
    
    return (
        <AdminCommonListLayout
          active_key="currency_conversions"
          breadcrumbs={[
              { name: "admin_home" },
              {
                  name: "currency_conversions",
                  label: "Currency Conversion",
                  url: "/admin/currency_conversions",
              },
          ]}
          add_label={"Add Currency Conversion"}
          onAddRow={onAddCurrencyConversion}
          is_loading={is_loading}
          columns={columns}
          item_list={adminCurrencyConversionList}
        />
    )
}
