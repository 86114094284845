import React, { useEffect } from "react"
import { get, head } from 'lodash'
import { useHistory }  from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import AdminCommonListLayout from "./AdminCommonListLayout"
import { adminPhotoArtList } from "../actions/admin_photo_art"
import { makeStyles } from '@material-ui/core'
import Timestamp from '../../components/Timestamp'

const useStyles = makeStyles((theme) => ({
  gallery_event_image: {
      maxWidth: "120px",
  }
}))

export const AdminPhotoArts = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const is_loading = useSelector(() => adminPhotoArtList.isLoading())
    const classes = useStyles()
    
    const columns = [
        {
            field: "primary_key",
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'id' : '-id'),
            title: "ID",
        },
        {   field: 'created_at', title: 'Created Date',
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'created_at' : '-created_at'),
            render: (item) => <div>
                                <Timestamp value={get(item, "created_at")} format="dateshort-time" />
                              </div>,
        },
        {
            field: "photographer_name",
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'photographer_id__name_used' : '-photographer_id__name_used'),
            title: "Photographer Name",
        },
        {
            field: "genre_tag",
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'genre_tags' : '-genre_tags'),
            title: "Genre Tag"
        },
        {
            field: "rating",
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'rating' : '-rating'),
            title: "Rating"
        },
        {
            field: "available_to_purchase",
            title: "Avail. to Purchase",
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'available_to_purchase' : '-available_to_purchase'),
            render: (item) => <p>{get(item, "available_to_purchase").toString()}</p>
        },
        {
            field: "description",
            title: "Description"
        },
        {
            field: "tags",
            title: "Tags"
        }, 
        {
            field: "session_name",
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'session_name' : '-session_name'),
            title: "Session Name"
        },
        {
            field: "gallery_name",
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'gallery_name' : '-gallery_name'),
            title: "Gallery Name",
        },
        {
            field: "image",
            title: "Low res photo",
            render: (item) => <div><img className={classes.gallery_event_image} src={get(head(get(item, "photographs")), ["thumbnail_size_file_info", "download_url"])} /></div>
        },
        {
            field: "image",
            title: "High res photo",
            render: (item) => <div><a target="_blank" href={get(head(get(item, "photographs")), ["printable_size_file_info", "download_url"])}><p>{get(head(get(item, "photographs")), ["printable_size_file_info", "download_url"])}</p></a></div>
        },
        {
          field:  "edit",
          title:  "Edit",
          render: (item) => <div><button onClick={() => onEditPhotoArt(get(item, "id"))}>Edit</button></div>,
        },
    ]

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminPhotoArtList.fetchListIfNeeded())
        }
        fetchStaticObjects();
    }, [])

    const onUpdateListOrdering = (field) => {
        console.log(field)
        dispatch(adminPhotoArtList.updateListOrdering(field))
        dispatch(adminPhotoArtList.fetchListIfNeeded())
    }

    const onEditPhotoArt = (photo_art_id) => {
      history.push(`/admin/photo_art/${photo_art_id}`)
    }
    
    return (
        <AdminCommonListLayout
          active_key="photo_arts"
          breadcrumbs={[
              { name: "admin_home" },
              {
                  name: "photo_arts",
                  label: "PhotoArt",
                  url: "/admin/photo_arts",
              },
          ]}
          is_loading={is_loading}
          columns={columns}
          item_list={adminPhotoArtList}
        />
    )
}
