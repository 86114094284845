import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

export const Copyright = () => {
  return (
    <Typography variant="caption" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://bossfotos.org">BossFotos</Link>{' '}
      {new Date().getFullYear()} {' - All rights reserved'}
      <small>| version 2</small>
    </Typography>
  )
}
