import { ItemList } from "../../orm";
import { get, head, filter, lowerCase } from "lodash";

class CountryList extends ItemList {

  getEntityKey() {
    return "country";
  }

  getCountryForId(country_id) {
    return this.getObject(country_id)
  }
  
  getCountryNameForCountryPrintConfigurationId(country_print_configuration) {
    const country = head(filter(this.getAllObjects(), (country) => country.country_print_configuration === country_print_configuration))
    return get(country, "country_name")
  }

}

export const countryList = new CountryList("country_list_default")