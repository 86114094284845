import React from 'react'
import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { adminCacheList } from '../actions/admin_cache'
import {showSuccess, showError} from '../../actions/Error'

export const AdminCache = () => {
  const dispatch = useDispatch()

  const handleResetCache = () => {
    const response = window.confirm('Are you sure you want to reset cache?')

    const on_done = () => {
      showSuccess("Cache reset")
    }

    if (response) {
      dispatch(adminCacheList.resetCache()).then(on_done)
    }
  }
  
  return (
    <Button variant='contained' onClick={ handleResetCache }>
      Reset Cache
    </Button>
    )
}
