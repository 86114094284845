import React, { useState, useEffect } from "react"
import { get, map, filter, head } from "lodash"
import { useForm, useFieldArray } from "react-hook-form"
import { Button, makeStyles } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import SavePrintItemButton from "./SavePrintItemButton"
import { FileUploader } from "../../../components/form/FileUploader"
import { Modal } from '../../../components/layout/Modal'
import { adminImageWithCutoutList } from '../../actions/admin_image_with_cutouts'
import { showError } from '../../../actions/Error'
import { cutoutImageList } from '../../../actions/cutout_image'
import ReactLassoSelect, { getCanvas } from 'react-lasso-select'

const useStyles = makeStyles({
    cancelFileUploadButton: {
        position: "relative",
        zIndex: "99999"
    },
    imagePreview: {
        width: "200px"
    },
    imagePreviewContainer: {
        width: "300px",
        display: "flex",
        alignItems: "center"
    }
})

export const ImageWithCutoutForm = ({imageWithCutoutId, onUpdated, onCancel}) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const imageWithCutout = useSelector(() => imageWithCutoutId && adminImageWithCutoutList.getObject(imageWithCutoutId))
    const [clippedImg, setClippedImg] = useState()

    const defaultValues = { "image": get(imageWithCutout, "image"),
                            "image_file_info": get(imageWithCutout, "image_file_info")
                          }
    const [points, setPoints] = useState(adminImageWithCutoutList.getCutoutAsPoints(imageWithCutout))
    
    const { register, handleSubmit, setValue, getValues, watch } = useForm({defaultValues})

    useEffect(() => {
        if ( imageWithCutoutId ) {
            dispatch(adminImageWithCutoutList.ensureObjectLoaded(imageWithCutoutId))
        }
    }, [imageWithCutoutId])

    useEffect(() => {
        setPoints(adminImageWithCutoutList.getCutoutAsPoints(imageWithCutout))
    }, [imageWithCutout])
    
    const imageFileInfo = watch("image_file_info")
    const onUploadedFile = (({file_info}) => {
        setValue("image", file_info.id)
        setValue("image_file_info", file_info)
    })

    const onSave = () => {
        const values = Object.assign({}, getValues(), adminImageWithCutoutList.getCutoutFromPoints(points))
        const on_done = function (json) {
            if ( json.id ) {
                dispatch(cutoutImageList.invalidateForCutout({imageWithCutout: json}))
                onUpdated({imageWithCutout: json})
            } else {
                console.error(json)
            }
        }

        if (imageWithCutoutId) {
            values.id = imageWithCutoutId
            return dispatch(adminImageWithCutoutList.saveObject(values)).then(on_done)
        } else {
            return dispatch(adminImageWithCutoutList.saveNewObject(values)).then(on_done)
        }
    }

    const renderForLasso = () => {
        return (
            <div className={classes.imagePreviewContainer}>
              <ReactLassoSelect
                value={points}
                src={imageFileInfo.download_url}
                onChange={value => {
                    setPoints(value)
                }}
                onComplete={(value) => {
                    if (!value.length) {
                        return
                    }
                    getCanvas(imageFileInfo.download_url, value, (err, canvas) => {
                        if (!err) {
                            setClippedImg(canvas.toDataURL())
                        }
                    })
                }}
              />
              <div>
                Points: {points.map(({x, y}) => `${x},${y}`).join(' ')}
              </div>
              <div>
                <img src={clippedImg} alt="" />
              </div>
            </div>
        )        
    }
    
    return (

        <Modal title="Edit image"
               onClose={onCancel}
               fullScreen
        >
          <div>
            Editing carousel image with cutout

            <FileUploader
              name="event_photograph_file_info_for_saving"
              label="Image"
              onChange={(file_info) => onUploadedFile({file_info})} 
            />

            { get(imageFileInfo, "download_url") &&
              <>
                <span>{imageFileInfo.download_url}</span>
                {renderForLasso()}
              </>
            }
            
            <Button variant="danger" onClick={onCancel}>
              Cancel
            </Button>
            
            <Button variant="success" onClick={onSave}>
              Save
            </Button>
          </div>
        </Modal>
    )
    
    
}
