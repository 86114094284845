import React, { useEffect } from "react"
import { get } from 'lodash'
import { useHistory }  from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import AdminCommonListLayout from "./AdminCommonListLayout"
import { adminGenreTagsList } from "../actions/admin_genre_tags"
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  genre_tag_image: {
      maxWidth: "120px",
  }
}))


export const AdminGenreTags = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const is_loading = useSelector(() => adminGenreTagsList.isLoading())
    const classes = useStyles()

    
    const columns = [
        {
            field: "name",
            title: "Genre Tag Name",
        },
        {
            field: "image",
            title: "Image",
            render: (item) => <div><img className={classes.genre_tag_image} src={get(item, ["genre_image", "gallery_size_file_info", "download_url"])} /></div>
        },
        {
          field:  "edit",
          title:  "Edit",
          render: (item) => <div><button onClick={() => onEditGenreTag(get(item, "id"))}>Edit</button></div>,
        },
    ]

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminGenreTagsList.fetchListIfNeeded())
        }
        fetchStaticObjects();
    }, [])

    const onAddGenreTag = () => {
        history.push("/admin/genre_tag/")
    }

    const onEditGenreTag = (genre_tag_id) => {
       history.push(`/admin/genre_tag/${genre_tag_id}`)
    }
    
    return (
        <AdminCommonListLayout
          active_key="genre_tags"
          breadcrumbs={[
              { name: "admin_home" },
              {
                  name: "genre_tags",
                  label: "Genre Tags",
                  url: "/admin/genre_tags",
              },
          ]}
          add_label={"Add genre"}
          onAddRow={onAddGenreTag}
          is_loading={is_loading}
          columns={columns}
          item_list={adminGenreTagsList}
        />
    )
}
