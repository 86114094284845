import React, { useState, useEffect } from "react"
import { get, map } from 'lodash'
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux'
import AddPrintItemButton from './AddPrintItemButton'
import WrappingBusyMask from '../../../components/WrappingBusyMask'
import { AvailablePurchaseOptionForm } from './AvailablePurchaseOptionForm'
import { adminPrintPurchasesList } from '../../actions/print/admin_print_purchase_options'

export default function AvailablePurchaseOptions({ country_print_configuration_id }) {
    const dispatch = useDispatch()
    const { formState: { errors } } = useForm()
    const purchaseOptions = useSelector(() => adminPrintPurchasesList.getVisibleObjects())
    const [isAddingPrintPurchaseOption, setIsAddingPrintPurchaseOption] = useState(false)
    const isLoading = useSelector(() => adminPrintPurchasesList.isLoading())

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminPrintPurchasesList.trySetFilter({ country_print_configuration: country_print_configuration_id }))
            dispatch(adminPrintPurchasesList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    const onAddPrintPurchaseOption = () => {
        setIsAddingPrintPurchaseOption(true)
    }

    const onUpdated = () => {
        setIsAddingPrintPurchaseOption(false)
    }

    return (
        <WrappingBusyMask is_loading={isLoading}>
          {map(purchaseOptions, (printPurchaseOption) =>
               <>
                 <AvailablePurchaseOptionForm onUpdated={onUpdated}
                                              key={get(printPurchaseOption, "id")}
                                              printPurchaseOption={printPurchaseOption}
                                              country_print_configuration_id={country_print_configuration_id}
                 /> 
               </>
              )}
          { isAddingPrintPurchaseOption && 
            <AvailablePurchaseOptionForm onUpdated={onUpdated}
                                         printPurchaseOption={null}
                                         country_print_configuration_id={country_print_configuration_id}
            /> 
          }
          <AddPrintItemButton on_click_action={onAddPrintPurchaseOption}/>
        </WrappingBusyMask>
    )
}