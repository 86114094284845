import React, { useState, useEffect } from "react"
import { get, map } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import AddPrintItemButton from "./AddPrintItemButton"
import { adminPrintTypesList } from "../../actions/print/admin_print_types"
import AvailablePrintTypeForm from "./AvailablePrintTypeForm"

export default function AvailablePrintTypes({ country_print_configuration_id }) {
    const dispatch = useDispatch()
    const printTypes = useSelector(() => adminPrintTypesList.getVisibleObjects())
    const [isAddingPrintTypeOption, setIsAddingPrintTypeOption] = useState(false)
    
    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminPrintTypesList.trySetFilter({ country_print_configuration: country_print_configuration_id }))
            dispatch(adminPrintTypesList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    const onAddPrintTypeOption = () => {
        setIsAddingPrintTypeOption(true)
    }

    const onUpdated = () => {
        setIsAddingPrintTypeOption(false)
    }

    return (
        <>
          {map(printTypes, (printType) =>
               <>
                 <AvailablePrintTypeForm key={get(printType, "id")} printType={printType} onUpdated={onUpdated} country_print_configuration_id={country_print_configuration_id}/>
               </>
              )}
          { isAddingPrintTypeOption && 
            <AvailablePrintTypeForm printType={null}
                                    country_print_configuration_id={country_print_configuration_id} 
                                    onUpdated={onUpdated}
            /> 
          }
          <AddPrintItemButton on_click_action={onAddPrintTypeOption}/>
        </>
    )
}
