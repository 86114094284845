import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useHistory }  from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AdminCommonListLayout from './AdminCommonListLayout'
import { adminGalleryEventsList } from '../actions/admin_gallery_events'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  gallery_event_image: {
      maxWidth: '120px',
  }
}))

export const AdminGalleryEvents = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const is_loading = useSelector(() => adminGalleryEventsList.isLoading())
    
  const columns = [
    {
      field: 'name',
      title: 'Gallery Event Name',
    },
    {
      field: 'image',
      title: 'Image',
      render: (item) =>
        <div>
          <img
            className={ classes.gallery_event_image }
            src={get(item, ['event_photograph', 'gallery_size_file_info', 'download_url'])}
          />
        </div>
    },
    {
      field:  'edit',
      title:  'Edit',
      render: (item) =>
        <div>
          <button
            onClick={() => onEditGalleryEvent(get(item, 'id'))}>
            Edit
          </button>
        </div>
    },
  ]

  useEffect(() => {
    async function fetchStaticObjects() {
      dispatch(adminGalleryEventsList.fetchListIfNeeded())
    }
    fetchStaticObjects()
  }, [])
  
  const onAddGalleryEvent = () => {
    history.push('/admin/gallery_event/')
  }
  
  const onEditGalleryEvent = (gallery_event_id) => {
    history.push(`/admin/gallery_event/${ gallery_event_id }`)
  }
  
    return (
      <AdminCommonListLayout
        active_key='gallery_events'
        breadcrumbs={[
          { name: 'admin_home' },
          {
            name: 'gallery_events',
            label: 'Gallery Events',
            url: '/admin/gallery_events',
          },
        ]}
        add_label='Add gallery event'
        columns={ columns }
        is_loading={ is_loading }
        onAddRow={ onAddGalleryEvent }
        item_list={ adminGalleryEventsList }
      />
    )
}
