import React, { useState, useEffect } from "react"
import { get, map, filter, head } from "lodash"
import { useForm, useFieldArray } from "react-hook-form"
import { Button, makeStyles } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import SavePrintItemButton from "./SavePrintItemButton"
import { FileUploader } from "../../../components/form/FileUploader"
import { Modal } from '../../../components/layout/Modal'
import { adminImageWithCutoutList } from '../../actions/admin_image_with_cutouts'
import { showError } from '../../../actions/Error'
import { adminPhotographList } from '../../actions/admin_photographs'
import { cutoutImageList } from '../../../actions/cutout_image'
import ReactLassoSelect, { getCanvas } from 'react-lasso-select'
import WrappingBusyMask from '../../../components/WrappingBusyMask'
import Loading from '../../../components/Loading'
import { PreviewImageWithCutout } from './PreviewImageWithCutout'
import clsx from 'clsx'

const useStyles = makeStyles({
    container: {
    },
    photographList: {
        display: "flex",
        flexWrap: "row",
    },
    photographInList: {
        cursor: "pointer",
        border: "1px solid black",
        "&:hover": {
            border: "1px solid red",
        },
    },
    photographImgInList: {
        width: "100px",
        margin: "10px"
    },
    previewFullSize: {
        width: "1000px",
    },
    previewThumbnail: {
        width: "200px",
    }
})

export const PreviewImageWithCutoutModal = ({imageWithCutoutId, onClose, imageClass}) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const imageWithCutout = useSelector(() => imageWithCutoutId && adminImageWithCutoutList.getObject(imageWithCutoutId))
    const [clippedImg, setClippedImg] = useState()
    const photographs = useSelector(() => adminPhotographList.getVisibleObjects())
    const [photograph, setPhotograph] = useState(null)
    const cutoutImage = useSelector(() => cutoutImageList.getCutoutImage({imageWithCutout, photograph}))
    const isLoadingCutout = useSelector(() => cutoutImageList.isLoading())

    useEffect(() => {
        dispatch(adminPhotographList.fetchListIfNeeded())
    }, [adminPhotographList])
    
    useEffect(() => {
        if ( imageWithCutoutId ) {
            dispatch(adminImageWithCutoutList.ensureObjectLoaded(imageWithCutoutId))
        }
    }, [imageWithCutoutId])

    useEffect(() => {
        dispatch(cutoutImageList.ensureCutoutImageLoaded({imageWithCutout, photograph}))
    }, [get(photograph, "id")])

    const renderPhotographs = () => {
        return (
            <div className={classes.photographList}>
              {map(photographs, (x) => (
                  <>
                    {get(x, "gallery_size_file_info") &&
                     <div className={classes.photographInList} onClick={() => setPhotograph(x)}>
                       <img className={classes.photographImgInList} src={x.gallery_size_file_info.download_url} />
                     </div>
                    }
                  </>
              ))}
            </div>
        )
    }
    
    return (
        <Modal title="Preview image"
               onClose={onClose}
               fullScreen
        >
          <div>
            Preview carousel image with cutout using a recent photograph

            <div>
              <Button variant="success" onClick={onClose}>
                Close
              </Button>
            </div>
            <div>
              { renderPhotographs() }
            </div>
            
            <div>
              <div className={classes.container}>
                Full size:
                <PreviewImageWithCutout imageWithCutoutId={imageWithCutoutId} photograph={photograph} imageClass={classes.previewFullSize} />

                <br/>

                Thumbnail:
                <PreviewImageWithCutout imageWithCutoutId={imageWithCutoutId} photograph={photograph} imageClass={classes.previewThumbnail} />
              </div>
            </div>
          </div>

        </Modal>
    )
    
    
}
