import React, { useEffect } from 'react'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/core'
import { useHistory }  from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AdminCommonListLayout from './AdminCommonListLayout'
import { adminPromotionalMediasList } from '../actions/admin_promotional_medias'

const useStyles = makeStyles((theme) => ({
  promotional_media_photograph: {
    maxWidth: '120px',
  }
}))

export const AdminPromotionalMedias = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const is_loading = useSelector(() => adminPromotionalMediasList.isLoading())
  
  const columns = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'image',
      title: 'Image',
      render: (item) =>
      <div>
        <img
          className={ classes.promotional_media_photograph }
          src={get(item, ['promotional_photograph', 'gallery_size_file_info', 'download_url'])}
        />
      </div>
    }
  ]

  useEffect(() => {
    async function fetchStaticObjects() {
      dispatch(adminPromotionalMediasList.fetchListIfNeeded())
    }
    fetchStaticObjects()
  }, [])
  
  const onAddPromotionalMedia = () => 
        history.push('/admin/promotional_media/')
  
  return (
    <AdminCommonListLayout
      active_key='promotional_medias'
      breadcrumbs={[
        { name: 'admin_home' },
        {
          name: 'promotional_medias',
          label: 'Promotional Medias',
          url: '/admin/promotional_medias'
        },
      ]}
      columns={ columns }
      is_loading={ is_loading }
      onAddRow={ onAddPromotionalMedia }
      add_label='Add promotional media'
      item_list={ adminPromotionalMediasList }
    />
  )
}
