import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams }  from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

import { countryList } from "../actions/admin_countries"
import { adminCountryPrintConfigurationsList } from "../actions/admin_country_print_configurations"

import AdminMainLayout from './AdminMainLayout'
import AdminPrintHeader from "./print/AdminPrintHeader"
import AvailablePrintTypes from "./print/AvailablePrintTypes"
import AvailablePrintSizes from "./print/AvailablePrintSizes"
import AvailablePrintPaper from "./print/AvailablePrintPaper"
import AvailablePrintFrames from "./print/AvailablePrintFrames"
import AvailablePurchaseOptions from "./print/AvailablePurchaseOptions"

const useStyles = makeStyles((theme) => ({
  table: {
    margin: "auto",
  },
  container: {
    margin: "0 50px",
  },
  tableRoot: {
    width: "100%",
  },
  availablePrintTypeTable: {
    background: "#e7f2ea"
  },
  availableOptionsDiv: {
    background: "#a4cdda",
  },
  availableOptionsTable: {
    display:"grid",
    margin: "auto",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    justifyItems: "center",
  },
  availablePurchaseOptionsTable: {
    backgroundColor: "#fdf5e6",
  },
  rowItems: {
    display: "inline-block",
  },
}))

export const AdminCountryPrintConfiguration = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { country_print_configuration_id } = useParams()
  const country_name = useSelector(() => countryList.getCountryNameForCountryPrintConfigurationId(country_print_configuration_id))
  
  useEffect(() => {
    async function fetchStaticObjects() {
      dispatch(adminCountryPrintConfigurationsList.fetchListIfNeeded())
      dispatch(countryList.fetchListIfNeeded())
    }
    fetchStaticObjects();
  }, [])

    
    return (
      <>
        <AdminMainLayout>
          <div className={classes.container}>
          <AdminPrintHeader country_print_configuration_id={ country_print_configuration_id }/>
          <div>
            <h2>Print types for { country_name }</h2>
          </div>
          <div className={`${classes.tableRoot}  ${classes.availablePrintTypeTable}`}>
            <table className={`${classes.table}`}>
              <tr>
                <td>
                  <AvailablePrintTypes country_print_configuration_id={ country_print_configuration_id } />
                </td>
              </tr>
            </table>
          </div>
          <div>
            <h2>Available options for { country_name }</h2>
          </div>
          <div className={`${classes.tableRoot} ${classes.availableOptionsDiv}`}>
            <table className={`${classes.table} ${classes.availableOptionsTable}`}>
              <tr>
                <td className={classes.rowItems}>
                  <h3>Size names</h3>
                  <AvailablePrintSizes country_print_configuration_id={ country_print_configuration_id } />
                </td>
              </tr>
              <tr>
                <td className={classes.rowItems}>
                  <h3>Frame type</h3>
                  <AvailablePrintFrames country_print_configuration_id={ country_print_configuration_id } />
                </td>
              </tr>
              <tr>
                <td className={classes.rowItems}>
                  <h3>Paper type</h3>
                  <AvailablePrintPaper country_print_configuration_id={ country_print_configuration_id } />
                </td>
              </tr>
            </table>
          </div>
          <div>
            <h2>Available purchase options for {country_name} </h2>
          </div>
          <div className={`${classes.tableRoot} ${classes.availablePurchaseOptionsTable}`}>
            <table className={`${classes.table}`}>
              <tr>
                <td>
                  <AvailablePurchaseOptions country_print_configuration_id={ country_print_configuration_id } />
                </td>
              </tr>
            </table>
          </div>
      </div>
        </AdminMainLayout>
      </>
    )
}
