import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useHistory }  from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AdminCommonListLayout from './AdminCommonListLayout'
import { adminAlbumsList } from '../actions/admin_albums'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  album_image: {
      maxWidth: '120px',
  }
}))

export const AdminAlbums = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const is_loading = useSelector(() => adminAlbumsList.isLoading())

  const columns = [
    {
      field: 'name',
      title: 'Album Name',
    },
    {
      field:  'edit',
      title:  'Edit',
      render: (item) =>
        <div>
          <button
            onClick={() => onEditAlbum(get(item, 'id'))}>
            Edit
          </button>
        </div>
    },
  ]

  useEffect(() => {
    async function fetchStaticObjects() {
      dispatch(adminAlbumsList.fetchListIfNeeded())
    }
    fetchStaticObjects()
  }, [])

  const onAddAlbum = () => {
    history.push('/admin/album/')
  }

  const onEditAlbum = (album_id) => {
    history.push(`/admin/album/${ album_id }`)
  }

    return (
      <AdminCommonListLayout
        active_key='albums'
        breadcrumbs={[
          { name: 'admin_home' },
          {
            name: 'albums',
            label: 'Albums',
            url: '/admin/albums',
          },
        ]}
        add_label='Add album'
        columns={ columns }
        is_loading={ is_loading }
        onAddRow={ onAddAlbum }
        item_list={ adminAlbumsList }
      />
    )
}
