import React, { Component } from "react"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { withRouter } from "react-router-dom"
import { css } from "@emotion/core"
import AdminMainLayout from './AdminMainLayout'
import AdminPhotoArtForm from "./AdminPhotoArtForm"
import { adminPhotoArtList } from "../actions/admin_photo_art"
import { countryList } from '../../admin/actions/admin_countries'

class AdminPhotoArt extends Component {
  componentDidMount() {
    const { dispatch, photo_art_id } = this.props
    dispatch(adminPhotoArtList.ensureObjectLoaded(photo_art_id))
  }

  
  render() {
    const {photo_art, initial_values } = this.props
      return (
          <AdminMainLayout>
            <h2>Edit</h2>
             <AdminPhotoArtForm photo_art={photo_art} initial_values={initial_values} />
          </AdminMainLayout>
      )
  }
}

function mapStateToProps(state, props) {
  const { photo_art_id } = props.match.params 
  const photo_art = adminPhotoArtList.getObject(photo_art_id)
  const initial_values = photo_art
  return {
    photo_art_id,
    photo_art,
    initial_values,
    is_busy: adminPhotoArtList.isLoading()
  }
}
export default withRouter(connect(mapStateToProps)(AdminPhotoArt))

const image = css`
  width: 80%;
`