import React from "react"
import { get } from "lodash"
import { Button } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import SavePrintItemButton from "./SavePrintItemButton"
import { adminPrintFrameOptionsList, adminPrintFramesList } from "../../actions/print/admin_print_frames"

export default function AvailablePrintFrameForm({ printFrameOption, country_print_configuration_id, onUpdated }) {
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm({
    defaultValues: { 
      name: get(printFrameOption, "name"),
      enabled: get(printFrameOption, "enabled") 
    }
  })
  
  const onSubmit = (values) => {
    values.country_print_configuration = country_print_configuration_id

    const on_done = function () {
      dispatch(adminPrintFramesList.invalidateList())
      dispatch(adminPrintFramesList.fetchListIfNeeded())
      dispatch(adminPrintFrameOptionsList.invalidateList())
      dispatch(adminPrintFrameOptionsList.fetchListIfNeeded())
      onUpdated()
    }
    
    if (printFrameOption) {
      values.id = printFrameOption.id
      return dispatch(adminPrintFramesList.saveObject(values)).then(on_done)
    }else {
      return dispatch(adminPrintFramesList.saveNewObject(values)).then(on_done)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input 
        type="checkbox"
        {...register("enabled")} 
        defaultChecked={printFrameOption === null ? true : false}
      />
      <input
        {...register("name")} 
      />
      <Button type="submit">
          <SavePrintItemButton/>
      </Button>
    </form>
  )
}