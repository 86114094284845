import React from "react"
import { makeStyles, Typography, Box, Card, CardContent, CardActions, Button, Divider } from "@material-ui/core"
import { spacing } from '@material-ui/system'
import { FormikGeneralFormErrors } from '../form/GeneralFormErrors'

const useStyles = makeStyles(() => ({
    alert: {
        marginBottom: '7px'
    },
    card: {
        maxWidth: '100%',
        marginTop: 0,
        width: "100%",
    },
    container: {
        justifyContent: "center",
        width: "100%",
        marginBottom: 60
    },
    containerInDrawer: {
      maxWidth: 455,
      marginBottom: 60
    },
    actions: {
      width: "100%",
      float: "right",
      padding: "0 15px 15px 15px"
    },
    positionFixed: {
      position: 'absolute'
    },
    positionAbsolute: {
      position: 'fixed'
    },
    actionsInDrawer: {
      bottom: 0,
      width: "100%",
      padding: 0
    },
    drawerInnnerActionsDiv: {
      width: "470px",
      bottom: 0,
      right: 0,
      padding: '7px 15px',
      background: '#ffffff',
      boxShadow: '0px -4px 3px rgba(239, 239, 239, 1)'
    },
    buttons: {
      bottom: 0,
      right: 0,
      width: '100%',
      padding: '7px 15px',
      zIndex: 999,
      background: '#ffffff',
      boxShadow: '0px -4px 3px rgba(239, 239, 239, 1)'
    }
}))

const FormCard = ({title, children, isSubmitting, onCancel, cardIsInDrawer, elevation, variant, save_label, renderSaveButtons, positionFixed=false}) => {
    const classes = useStyles()
    const position = positionFixed ? 'fixed' : 'absolute'
    let actions = classes.actions
    let buttons = classes.buttons
    let container = classes.container
    if(!elevation) { elevation = 0}
    if(cardIsInDrawer) {
      elevation = 0
      actions = classes.actionsInDrawer
      buttons = classes.drawerInnnerActionsDiv
      container = classes.containerInDrawer
    }
    return (
      <div className={container}>
        <Card className={classes.cardNoborder} elevation={elevation} variant={variant}>

          <CardContent>
            { title && <Typography variant="h6">{title}</Typography>}
            <FormikGeneralFormErrors />
            {children}
          </CardContent>
        </Card>
      </div>
    )
}

export default FormCard
