import React, { Component } from "react"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { withRouter } from "react-router-dom"
import { css } from "@emotion/core"
import AdminMainLayout from './AdminMainLayout'
import AdminCurrencyConversionForm from "./AdminCurrencyConversionForm"
import { adminCurrencyConversionList } from "../actions/admin_currency_conversion"
import { countryList } from '../../admin/actions/admin_countries'

class AdminCurrencyConversion extends Component {
  componentDidMount() {
    const { dispatch, currency_conversion_id } = this.props
    dispatch(adminCurrencyConversionList.invalidateList())
    dispatch(adminCurrencyConversionList.ensureObjectLoaded(currency_conversion_id))
    dispatch(countryList.invalidateList())
    dispatch(countryList.fetchListIfNeeded())
  }

  onSave = (values) => {
    console.log(values)
    const { dispatch, history, currency_conversion_id } = this.props
      const on_done = function () {
          dispatch(adminCurrencyConversionList.invalidateList())
          dispatch(adminCurrencyConversionList.fetchListIfNeeded())
          history.push("/admin/currency_conversions")
        }
        if (currency_conversion_id) {
          values.id = currency_conversion_id
          return dispatch(adminCurrencyConversionList.saveObject(values)).then(on_done)
        } else {
          return dispatch(adminCurrencyConversionList.saveNewObject(values)).then(on_done)
        }
  }
  
  render() {
    const {currency_conversion, country_options, initial_values } = this.props
      return (
          <AdminMainLayout>
            <h2>{`${isEmpty(initial_values) ? "Add new" : "Edit"}`} </h2>
             <AdminCurrencyConversionForm onSave={this.onSave} country_options={country_options} currency_conversion={currency_conversion} initial_values={initial_values} />
          </AdminMainLayout>
      )
  }
}

function mapStateToProps(state, props) {
  const { currency_conversion_id } = props.match.params 
  const currency_conversion = adminCurrencyConversionList.getObject(currency_conversion_id)
  const country_options = countryList.getVisibleObjects()
  const initial_values = currency_conversion
  return {
    currency_conversion_id,
    currency_conversion,
    country_options,
    initial_values,
    is_busy: adminCurrencyConversionList.isLoading()
  }
}
export default withRouter(connect(mapStateToProps)(AdminCurrencyConversion))

const image = css`
  width: 80%;
`
