import React from "react"
import IconButton from "@mui/material/IconButton"
import AddCircleIcon from "@mui/icons-material/AddCircle"

export default function AddPrintItemButton({ on_click_action }) {  
  return (
    <div>
      <IconButton onClick={ on_click_action} aria-label="add item">
          <AddCircleIcon />
      </IconButton>
    </div>
  )
}
