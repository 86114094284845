import React, { useState, useEffect } from "react"
import { get, map } from 'lodash'
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
    error: {
        color: "red"
    },
})

export const MaterialInput = ({name, form, ...props}) => {

    const { formState: {errors}, register } = form
    const field_errors = get(errors, name)
    const classes = useStyles()

    return (
        <>
          <input {...register(name)} {...props} />
          { field_errors && map(field_errors.message, (x) => <p className={classes.error}>{x}</p>) }
        </>
    )
    
}
