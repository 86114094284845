import { ItemList } from '../orm'
import { get, head, size, map, filter, forEach } from 'lodash'
import moment from 'moment'

export const DEFAULT_COUNTRY_CODE = "au"

class PrintableAwsUploadSigninUrl extends ItemList {
    
    getEntityKey() {
        return 'file_info/aws_upload_signin_url'
    }

  getUploadUrl({file_name}) {
        return this.saveNewObject({country_code: DEFAULT_COUNTRY_CODE,
                                   file_name: file_name})
    }


}

export const printableAwsUploadSigninUrl = new PrintableAwsUploadSigninUrl("printable_aws_upload_signin_url")

