import React from "react"
import { get } from 'lodash'
import { Button } from '@material-ui/core'
import { useForm } from "react-hook-form"
import { useDispatch } from 'react-redux'
import SavePrintItemButton from "./SavePrintItemButton"
import { adminPrintSizeOptionsList, adminPrintSizesList } from '../../actions/print/admin_print_sizes'

export default function AvailablePrintSizeForm({ printSizeOption, country_print_configuration_id, onUpdated }) {
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm({
    defaultValues: { 
      name: get(printSizeOption, "name"),
      enabled: get(printSizeOption, "enabled") 
    }
  })
  
  const onSubmit = (values) => {
    values.country_print_configuration = country_print_configuration_id

    const on_done = function () {
      dispatch(adminPrintSizesList.invalidateList())
      dispatch(adminPrintSizesList.fetchListIfNeeded())
      dispatch(adminPrintSizeOptionsList.invalidateList())
      dispatch(adminPrintSizeOptionsList.fetchListIfNeeded())
      onUpdated()
    }
    
    if (printSizeOption) {
      values.id = printSizeOption.id
      return dispatch(adminPrintSizesList.saveObject(values)).then(on_done)
    }else {
      return dispatch(adminPrintSizesList.saveNewObject(values)).then(on_done)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <input 
            type="checkbox"
            {...register("enabled")} 
            defaultChecked={printSizeOption === null ? true : false}
        />
         <input
            {...register("name")} 
         />
        <Button type="submit">
            <SavePrintItemButton/>
        </Button>
    </form>
  )
}