import { ItemList } from "../../../orm"

class AdminPrintPaperList extends ItemList {

  getEntityKey() {
    return "admin/print_paper";
  }

  trySetFilter(newFilter) {
    return async (dispatch) => {
        const existingFilter = this.getFilter()
        if ( JSON.stringify(existingFilter) === JSON.stringify(newFilter) ) {
            return
        }
        dispatch(this.updateListFilter(newFilter))
        dispatch(this.updatePaginationNumItemsPerPage(20))
        dispatch(this.fetchListIfNeeded())
    }
}

}

export const adminPrintPaperList = new AdminPrintPaperList("admin_print_paper__default")
export const adminPrintPaperOptionsList = new AdminPrintPaperList("admin_print_paper_options__default")