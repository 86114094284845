import React from 'react'
import { makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        width:'100%',
        height:'100%',
        backgroundColor: '#eceaea',
        backgroundSize: 50,
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center',
        zIndex:10000000,
        opacity: 0.8,
        filter: 'alpha(opacity=80)',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "30px"
    }
}))

export default function Loading({msg}) {
    const classes = useStyles()
    return (
        <div className={classes.loader}>
          {msg || "Loading..."}
        </div>
    )
}
