import React from "react"
import { get, map } from "lodash"
import MenuItem from "@mui/material/MenuItem"
import { makeStyles, FormLabel, TextField } from "@material-ui/core"

const useStyles = makeStyles({
    error: {
        color: "red",
    },
    formLabel: {
        color: "#0c59cf",
        fontSize: "20px",
    },
    dropdown: {
        width: "100%"
    }
})

export const MaterialDropdown = ({
    name,
    field_label,
    option_label = "",
    options,
    variant = "standard",
    placeholder = "",
    form,
    enabled,
    value,
    ...props
}) => {
    const {formState: { errors },} = form
    const field_errors = get(errors, name)
    const classes = useStyles()

    return (
        <>
          { field_label && 
            <FormLabel className={classes.formLabel}>
              {field_label}
            </FormLabel>
          }

          <TextField
            select
            variant={variant}
            label={get(props, "label", placeholder)}
            className={classes.dropdown}
            InputLabelProps={{ shrink: Boolean(value) }}
            value={value || null}
            {...props}
          >
            {map(options, (option, index) => (
                <MenuItem value={option.value} key={index}>
                  <span>{option.label}</span>
                </MenuItem>
            ))}
          </TextField>
          {field_errors && <p className={classes.error}>{field_errors.message}</p>}
        </>
    )
}