import React, { Component } from "react"
import { FormikInputField } from "../../components/form/InputField"
import { FileUploader } from "../../components/form/FileUploader"
import { Formik, Form  } from "formik"
import { get } from 'lodash'
import FormCard from "../../components/layout/FormCard"
import * as Yup from "yup"
import { FormikCheckboxField } from "../../components/form/CheckboxField"
import { Button } from "@material-ui/core"

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
})

class AdminGenreTagForm extends Component {
    
    render() {
        const { onSave, genre_tag, initial_values } = this.props
        const genre_tag_image_url = get(genre_tag, ["genre_tag_photograph", "gallery_size_file_info", "download_url"])
        
        const onFileUploaded = (formik_props, file_info) => {
          formik_props.setFieldValue('genre_image_file_info_for_saving', file_info)
        }

        return (
            <Formik
              initialValues={initial_values}
              onSubmit={onSave}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {(formik_props) => {
                  const { values } = formik_props
                  return (
                      <Form>
                        <FormCard>
                          <FormikInputField
                            name="name"
                            type="text"
                            label="Name"
                            formik_props={formik_props}
                          />
                        </FormCard>
                        <FormCard>
                          <FormikCheckboxField
                            name="enabled"
                            type="checkbox"
                            checked={values.enabled}
                            label="Enable Genre"
                            formik_props={formik_props}
                          />
                        </FormCard> 
                        <FormCard>
                          <FileUploader
                            name="genre_image_file_info_for_saving"
                            label="Image"
                            onChange={(file_info) => onFileUploaded(formik_props, file_info)} 
                            formik_props={formik_props}
                          />
                          {genre_tag_image_url && (
                              <div>
                                <h3>Current image:</h3>
                                <div><img src={genre_tag_image_url}/></div>
                              </div>
                          )} 
                        </FormCard>
                        <FormCard>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                          >
                            Save
                          </Button>
                        </FormCard>
                      </Form>
                  )
              }}
            </Formik>
        )
    }
}
export default AdminGenreTagForm;
