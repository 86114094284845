import React, { useEffect } from 'react'
import { get } from 'lodash'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikInputField } from '../../components/form/InputField'
import { FormikTextareaField } from '../../components/form/TextareaField'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { useDispatch } from 'react-redux'
import { CommonTable } from '../../components/CommonTable'
import AdminMainLayout from './AdminMainLayout'
import AdminTableFilter from './AdminTableFilter'
import { Separator } from '../../components/layout/Separator'
import { Button, Paper, Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    paddingLeft: 15,
    fontSize: 24,
  },
  labelShinked: {
    marginLeft: 5,
    fontSize: 16
  }
}))

const AdminCommonListLayout = ({title, active_key, add_label, canDelete, onAddRow, renderAdditionalFilter, enableAnyFieldFilter, renderAdditionalActions, onEditRow, is_loading, breadcrumbs, columns, item_list}) => {
    const classes = useStyles()
    const rows = item_list.getVisibleObjects()
    const dispatch = useDispatch()

    const onFilterChanged = (filter_values) => {
        dispatch(item_list.updateListFilter(filter_values))
        dispatch(item_list.fetchListIfNeeded())
    }

    const renderFilter = (formik_props) => {
        return (
            <div>
              { renderAdditionalFilter && renderAdditionalFilter(formik_props) }

              { enableAnyFieldFilter &&
                <FormikInputField name="any_field"
                                  formik_props={formik_props}
                                  show_search_icon={true}
                                  placeholder="Search..."
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      shrink: classes.labelShinked
                                    }
                                  }}
                                  InputProps={
                                    {style:{
                                      borderRadius: '40px',
                                      paddingLeft: 15,
                                      fontSize: 24
                                    }
                                  }} />
              }
              <Separator variant="w5" />
            </div>
        )
    }

    return (
        <AdminMainLayout active_key={active_key}
                         breadcrumbs={breadcrumbs}
                         title={title}>
          <Grid container justify="space-between">
            <Grid item>
            { add_label &&
              <>
                <Button variant="contained" color="primary" onClick={ onAddRow } size="large">{add_label}</Button>
                <Separator height={10} />
              </>
            }
            </Grid>
            <Grid item>
              { renderAdditionalActions && renderAdditionalActions() }
            </Grid>
          </Grid>

          <Paper variant="outlined" square>
            <AdminTableFilter
              label=""
              placeholder="Search"
              renderFilter={ renderFilter }
              updateOnChange={ onFilterChanged }
              style={{marginLeft: 10, marginRight: 10}}
            />

          <CommonTable
            is_loading={ is_loading }
            rows={rows}
            columns={ columns }
            item_list={item_list}
            onEditRow={onEditRow}
            canDelete={canDelete}
            render_additional_filter={renderAdditionalFilter}
          />
        </Paper>
    </AdminMainLayout>
  )
}

export default AdminCommonListLayout
