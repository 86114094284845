import { ItemList } from "../../orm";

class DropdownOptions extends ItemList {
    constructor(dropdown_name) {
        super(`admin_options__${dropdown_name}`)
        this.url = `admin/dropdown_options/${dropdown_name}`
    }

    getEntityKey() {
        return this.url
    }

}

export const galleryEventDropdownOptions = new DropdownOptions('get_all_gallery_events')
export const galleryDropdownOptions = new DropdownOptions('get_all_galleries')
export const photographerDropdownOptions = new DropdownOptions('get_all_photographers')
export const currencyDropdownOptions = new DropdownOptions('get_all_currencies')
export const albumDropdownOptions = new DropdownOptions('get_all_albums')
export const voucherTypeDropdownOptions = new DropdownOptions('get_all_voucher_types')
export const voucherStatusDropdownOptions = new DropdownOptions('get_all_voucher_statuses')
