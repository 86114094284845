import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import { includes, get } from 'lodash'
import WrappingBusyMask from './WrappingBusyMask'
import { AdminRouter } from '../admin/components/AdminRouter'
import NewPassword from './NewPassword'
import Login from './Login'
import { Modal } from './layout/Modal'
import { Link } from 'react-router-dom'
import { isLoggedIn, auto_login } from '../actions/auth'
import { userList } from '../actions/user'
import { getGeneralApiError, hasGeneralApiError } from '../orm/orm_general_actions'

class AuthRouter extends Component {

    constructor(props) {
        super(props)
        this.state = {auto_logging_in: false}
    }

    componentDidMount() {
        const { dispatch, is_logged_in } = this.props
        this.check_for_auto_login()
        dispatch(userList.ensureUserLoaded())
    }

    componentDidUpdate(prevProps) {
        const { dispatch, is_logged_in, history, is_staff, is_superuser } = this.props
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/')
        } else if ( ( is_staff || is_superuser) && window.location.pathname === "/" ) {
            history.push('/admin/dashboard')
        }
        this.check_for_auto_login()
        dispatch(userList.ensureUserLoaded())
    }

    check_for_auto_login() {
        const { history, dispatch, is_logged_in } = this.props
        const { auto_logging_in } = this.state
        const that = this
        const params = new window.URLSearchParams(window.location.search);
        const user_id = params.has("u") && params.get("u")
        const otp = params.has("o") && params.get("o")

        const on_done = (res) => {
            that.setState({auto_logging_in: false})
            if ( res.errors ) {
                that.setState({auto_login_error_message: res.errors})
            }
            params.delete("u")
            params.delete("o")
            history.push(window.location.pathname + "?" + params.toString())
        }

        if ( user_id && otp && !auto_logging_in ) {
            this.setState({auto_logging_in: true})
            dispatch(auto_login(user_id, otp)).then(on_done)
        }
    }

    renderLoggedInRoutes = () => {
        return (
            <Switch>
              <Route path="/admin" exact={ false } component={ AdminRouter } />
              <Route path="/login" exact={ true } component={ Login } />
            </Switch>
        )
    }

    renderNotLoggedInRoutes = () => {
        return (
            <Switch>
              <Route path="/" exact={ false } component={ Login } />
            </Switch>
        )
    }

    render() {
        const { is_logged_in, is_prod, user, has_usable_password, general_api_error } = this.props

        return (
            <>
              { is_logged_in && get(user, "id") && ! has_usable_password && <NewPassword /> }
              { !is_logged_in && this.renderNotLoggedInRoutes() }
              { is_logged_in && this.renderLoggedInRoutes() }

              { general_api_error &&
                <Modal>
                  <img
                    src="/static/images/no-db-connection.png"
                    alt="Error: No Database connection"
                    title="Error: No Database connection"
                    style={{marginBottom: -5}}
                  />
                </Modal>
              }

            </>
        )

    }
}

function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let is_staff = false
    let is_superuser = false
    const user = userList.getUser()
    if (is_logged_in) {
        is_staff = user && user.is_staff
        is_superuser = user && user.is_superuser
    }

    const general_api_error = getGeneralApiError(state)

    const is_prod = !includes(window.location.host, "localhost") && !includes(window.location.host, "staging")
    const has_usable_password = is_logged_in && user && user.has_usable_password
    return {
        user,
        is_logged_in,
        is_staff,
        is_superuser,
        is_prod,
        has_usable_password,
        general_api_error
    }
}

export default withRouter(connect(mapStateToProps)(AuthRouter))
