import React, { Component } from "react"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { withRouter } from "react-router-dom"
import { css } from "@emotion/core"
import AdminAlbumForm from "./AdminAlbumForm"
import AdminMainLayout from './AdminMainLayout'
import { adminAlbumsList } from "../actions/admin_albums"
import { galleryEventDropdownOptions } from '../actions/admin_dropdown_options'

class AdminAlbum extends Component {
  componentDidMount() {
      const { dispatch, album_id, galleryEventOptions } = this.props
    dispatch(adminAlbumsList.invalidateList())
      dispatch(adminAlbumsList.ensureObjectLoaded(album_id))
      dispatch(galleryEventDropdownOptions.fetchListIfNeeded())

  }

  onSave = (values) => {
    const { dispatch, history, album_id } = this.props
      const on_done = function () {
          dispatch(adminAlbumsList.invalidateList())
          dispatch(adminAlbumsList.fetchListIfNeeded())
          history.push("/admin/albums")
        }
        if (album_id) {
          values.id = album_id
          return dispatch(adminAlbumsList.saveObject(values)).then(on_done)
        } else {
          return dispatch(adminAlbumsList.saveNewObject(values)).then(on_done)
        }
  }

  render() {
      const {album, galleryEventOptions, initial_values, } = this.props
      return (
          <AdminMainLayout>
            <h2>
              {`${isEmpty(initial_values) ? "Add new" : "Edit"}`} album
            </h2>
            <AdminAlbumForm
              onSave={ this.onSave }
              album={ album }
              galleryEventOptions={galleryEventOptions}
              initial_values={ initial_values }
            />
          </AdminMainLayout>
      )
  }
}
function mapStateToProps(state, props) {
  const { album_id } = props.match.params
  const album = adminAlbumsList.getObject(album_id)
    const galleryEventOptions = galleryEventDropdownOptions.getVisibleObjects()
  const initial_values = album
  return {
    album_id,
    album,
    initial_values,
    galleryEventOptions,
    is_busy: adminAlbumsList.isLoading()
  }
}
export default withRouter(connect(mapStateToProps)(AdminAlbum))

const image = css`
  width: 80%;
`
