import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useHistory }  from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AdminCommonListLayout from './AdminCommonListLayout'
import { adminVouchersList } from '../actions/admin_vouchers'
import { makeStyles } from '@material-ui/core'
import { capitalize } from 'lodash'
import Timestamp from '../../components/Timestamp'

const useStyles = makeStyles((theme) => ({
  voucher_image: {
      maxWidth: '120px',
  }
}))

export const AdminVouchers = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const is_loading = useSelector(() => adminVouchersList.isLoading())

  const columns = [
    {
      field: 'code',
      title: 'Code',
    },
    {
      field: 'status',
      title: 'Status',
      render: (item) =>
        <div>
          { capitalize(get(item, 'status')) }
        </div>
    },
    {
      field: 'expires_at',
      title: 'Expiry Date',
      render: (item) => <div>
        <Timestamp value={get(item, "expires_at")} format="date" />
      </div>,
    },    
    {
      field: 'voucher_type',
      title: 'Type',
      render: (item) =>
        <div>
          { capitalize(get(item, 'voucher_type')) }
        </div>      
    },
    {
      field: 'currency_name',
      title: 'Currency',
    },
    {
      field: 'max_amount',
      title: 'Max Amount',
    },
    {
      field: 'max_photos',
      title: 'Max Photos',
    },
    {
      field: 'email',
      title: 'Email Address',
    },
    {
      field: 'gallery_name',
      title: 'Gallery',
    },
    {
      field: 'event_name',
      title: 'Event',
    },
    {
      field: 'photographer_name',
      title: 'Photographer',
    },    
    {
      field:  'edit',
      title:  'Edit',
      render: (item) =>
        <div>
          <button
            onClick={() => onEditVoucher(get(item, 'id'))}>
            Edit
          </button>
        </div>
    },
  ]

  useEffect(() => {
    async function fetchStaticObjects() {
      dispatch(adminVouchersList.fetchListIfNeeded())
    }
    fetchStaticObjects()
  }, [])

  const onAddVoucher = () => {
    history.push('/admin/voucher/')
  }

  const onEditVoucher = (voucher_id) => {
    history.push(`/admin/voucher/${ voucher_id }`)
  }

    return (
      <AdminCommonListLayout
        active_key='vouchers'
        breadcrumbs={[
          { name: 'admin_home' },
          {
            name: 'vouchers',
            label: 'Vouchers',
            url: '/admin/vouchers',
          },
        ]}
        add_label='Add voucher'
        columns={ columns }
        is_loading={ is_loading }
        onAddRow={ onAddVoucher }
        item_list={ adminVouchersList }
      />
    )
}
