import { ItemList } from "../../orm"

class AdminFacialRecognition extends ItemList {

    getEntityKey() {
        return "admin/facial_recognition";
    }

    indexFaces({photograph}) {
        return this.saveNewObject({action: "index",
                                   photograph_id: photograph.id})
    }
    
    indexForCustomer({photograph, customer_email}) {
        return this.saveNewObject({action: "index_customer",
                                   photograph_id: photograph.id,
                                   customer_email})
    }

    searchForCustomer({customer_email}) {
        return this.saveNewObject({action: "search_customer",
                                   customer_email})
    }

}

export const adminFacialRecognition = new AdminFacialRecognition("admin_facial_recognition__default");
