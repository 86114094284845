import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {showSuccess, showError} from '../actions/Error'
import { map, get, size , includes } from 'lodash'
import { FormikInputField } from './form/InputField'
import { Formik, Form, Field } from 'formik'
import { Button } from './layout/Button'
import FormikOnChange from './form/FormikAutoSave'
import { Grid, Switch, Tooltip, Table, TableHead, TableBody,
         TableRow, TableCell, TableContainer, TablePagination,
         TableSortLabel, Checkbox, Toolbar, Typography,
         Paper, IconButton, lighten, makeStyles, useTheme, Hidden} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { mdiFilterOutline } from '@mdi/js'
import { useDispatch } from 'react-redux'
import { updateListPagination, invalidateList, getPagination } from '../orm/orm_list_actions'
import { handleSubmitResult } from '../actions/form'
import { fetchListIfNeeded } from '../orm/http_adapter'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    highlight:
    theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
    : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
    },
    title: {
        flex: '1 1 100%'
    },
    filterRow: {
        marginLeft: 70,
        marginRight: 15,
        marginTop: 5,
        marginBottom: 5
    }
}))

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    inputLabel: {
        marginLeft: 15,
        fontSize: 24,
        '&.shrink': {
            fontSize: 16,
        }
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    filterButton: {
        paddingTop: 25,
        marginLeft: 7,
        float: 'left'
    },
    filterChips: {
        marginLeft: 10,
        overflow: 'auto',
        marginBottom: -5
    },
    selectView: {
        marginTop: 10
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    actionBar: {
        display: "flex",
    },
    mobileCell: {
        border: 'none',
        padding: '10px 15px',
        borderTop: '1px solid #efefef',
        margin: 0,
        cursor: 'pointer'
    },
    mobileRow: {
        margin: 0,
        padding: 0
    },
    action: {
        cursor: "pointer"
    },
    labelRoot: {
        paddingLeft: 15,
        fontSize: 24,
    },
    labelShinked: {
        marginLeft: 5,
        fontSize: 16
    }
}))

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(0),
    },
}))

const Filter = ({enhanced_filter, initial_filter_values, render_additional_filter}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const onFilter = (values, formik_funcs) => {
        dispatch(enhanced_filter.setAnyFieldFilter(values.any_field))
        formik_funcs.setSubmitting(false)
    }

    return (
        <Formik
          initialValues={initial_filter_values || {}}
          onSubmit={onFilter}
          enableReinitialize={true}
          validationSchema={null}
        >
          {formik_props => {
              const { values } = formik_props
              return (
                  <Form>
                    <FormikOnChange onChange={(values) => onFilter(values, formik_props)} />
                    <div>
                      <FormikInputField
                        key="any_field"
                        name="any_field"
                        shrink={true}
                        placeholder="Search..."
                        formik_props={formik_props}
                        InputLabelProps={{
                            classes: {
                                root: classes.labelRoot,
                                shrink: classes.labelShinked
                            }
                        }}
                        InputProps={{
                            style:{
                                borderRadius: '40px',
                                paddingLeft: 15,
                                fontSize: 24,
                            }
                        }} />
                      { render_additional_filter && render_additional_filter(formik_props) }
                    </div>
                  </Form>
              )}
          }
        </Formik>
    )
}
const EnhancedTableToolbar = ({enhanced_filter, canDelete, initial_filter_values, canFilter, render_additional_filter}) => {
    const classes = useToolbarStyles()
    return (
        <>
          { canFilter !== false &&
            <div className={classes.filterRow}>
              <Filter enhanced_filter={enhanced_filter}
                      initial_filter_values={initial_filter_values}
              />
            </div>
          }
        </>
    )
}

function TablePaginationActions(props) {
    const classes = useStyles1()
    const theme = useTheme()
    const { count, page, rowsPerPage, onChangePage } = props

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    }

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    }

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    }

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
          <Hidden smDown>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </Hidden>
        </div>
    )
}

export function CommonTable({ columns, rows, is_loading, showActions=true, rowClickRedirect, onSelectRow, canDelete, onEditRow, mobile, item_list, initial_filter_values, showFilters=true, filters, render_additional_filter, initialRowsPerPage }) {
    const classes = useStyles()
    const history = useHistory()
    initialRowsPerPage = initialRowsPerPage || 10
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('')
    const [selected, setSelected] = React.useState([])
    const [page, setPage] = React.useState(initialRowsPerPage)
    const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage)
    const dispatch = useDispatch()
    const enhanced_filter = showFilters && item_list.getEnhancedFilter && item_list.getEnhancedFilter()
  

    const actions = []
    if ( item_list && canDelete ) {
        actions.push({
            icon: <DeleteIcon style={{ color: red[300], marginLeft:10 }} />,
            tooltip: 'Delete',
            onClick: (rowData) => { onDelete(rowData) }
        })
    }
    if ( onEditRow ) {
        actions.push({
            icon: <EditIcon color="primary" />,
            tooltip: 'Edit',
            onClick: (rowData) => { onEdit(rowData) }
        })
    }
    if ( onSelectRow ) {
        actions.push({
            icon: <CheckBoxOutlineBlankIcon color="primary" />,
            tooltip: 'Select',
            onClick: (rowData) => { onSelectRow(rowData) }
        })
    }

    const handleSort = (column) => {
        if ( ! column.sort ) {
            return
        }
        const isAsc = order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(column.field)
        column.sort(order)
        dispatch(item_list.invalidateList())
        dispatch(item_list.fetchListIfNeeded())
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const onEdit = (rowData) => {
        if ( onEditRow ) {
            onEditRow(rowData.id)
        }
    }

    const onDelete = (rowData) => {
        if ( ! window.confirm("Are you sure you want to delete this row?") ) {
            return
        }

        const on_ok = (json) => {
            if ( json.status === 'success' ) {
                showSuccess("Deleted")
            } else {
                showSuccess("Deletion failed " % json.error_msg)
            }
        }

        dispatch(item_list.deleteObject(rowData.id)).then(on_ok)
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name)
            setSelected(newSelecteds)
            return
        } else {
            setSelected([])
        }
    }

    const handleChangePage = (event, newPage) => {
        dispatch(updateListPagination(item_list.listKey, {page: newPage+1}))
        dispatch(item_list.invalidateList())
        dispatch(item_list.fetchListIfNeeded())
    }

    const handleChangeRowsPerPage = (event) => {
        dispatch(updateListPagination(item_list.listKey, {items_per_page:parseInt(event.target.value, 10), page: 1}))
        dispatch(item_list.invalidateList())
        dispatch(item_list.fetchListIfNeeded())
    }

    const onRefresh = () => {
        dispatch(item_list.invalidateList())
        dispatch(item_list.fetchListIfNeeded())
    }

    const isSelected = (name) => selected.indexOf(name) !== -1

    const renderFilter = () => {
        return (
            <>
              <EnhancedTableToolbar enhanced_filter={enhanced_filter}
                                    numSelected={selected.length}
                                    canDelete={canDelete}
                                    item_list={item_list}
                                    initial_filter_values={initial_filter_values}
                                    canFilter={true}
                                    render_additional_filter={render_additional_filter}
              />

            </>
        )
    }

    const renderPagination = () => {
        return(
            <>
              <Button onClick={onRefresh}>Refresh</Button>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 1000]}
                component="div"
                count={get(item_list.getPagination(), "num_items", 0)}
                rowsPerPage={get(item_list.getPagination(), "items_per_page", rowsPerPage)}
                page={get(item_list.getPagination(), "current_page", 1) - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </>
        )
    }

    const handleRowClick = (row, column) => {
        if(rowClickRedirect) {
            map(columns, function(column, index) {
                if ( column.redirect ) {
                    history.push(column.redirect(row))
                }
            })}
    }

    return (
        <Grid container>
          { showFilters && enhanced_filter &&
            <Grid item xs={12}>{renderFilter()}</Grid>
          }

          <Grid item xs={12}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                {!mobile &&
                 <>
                   <TableHead>
                     <TableRow>
                       {map(columns, function(column) {
                           const can_sort = column.sort !== null && column.sort !== undefined
                           return (
                               <TableCell
                                 key={`heading_${column.field}`}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                                 onClick={() => handleSort(column)}
                                 width={column.width}
                               >
                                 { can_sort &&
                                   <TableSortLabel active={orderBy === column.field}
                                                   hideSortIcon={!column.sort}
                                                   direction={order}
                                   >
                                     {column.title}
                                   </TableSortLabel>
                                 }
                                 { ! column.sort &&
                                   <span>
                                     {column.title}
                                   </span>
                                 }

                               </TableCell>
                           )
                       })}
                       { size(actions) > 0 && showActions &&
                         <TableCell
                           key={`heading_actions`}
                         />
                       }
                     </TableRow>
                   </TableHead>

                   <TableBody>
                     {map(rows, (row, index) => {
                         const isItemSelected = isSelected(row.name)
                         const labelId = `enhanced-table-checkbox-${index}`

                         return (
                             <TableRow
                               hover
                               role="checkbox"
                               aria-checked={isItemSelected}
                               tabIndex={-1}
                               key={row.id}
                               selected={isItemSelected}
                             >
                               {map(columns, function(column, index) {
                                   let value = null
                                   if ( column.render ) {
                                       value = column.render(row)
                                   } else {
                                       value = get(row, column.field)
                                   }
                                   return (
                                       <TableCell key={index} scope="row">
                                         {value}
                                       </TableCell>
                                   )
                               })}

                               { size(actions) > 0 && showActions &&
                                 <TableCell className={classes.actionBar}  align="right">
                                   {map(actions, (action, index) => {
                                       return (
                                           <div key={index}
                                                className={classes.action}
                                                onClick={() => action.onClick(row)} >
                                             {action.icon}
                                           </div>
                                       )
                                   })}
                                 </TableCell>
                               }
                             </TableRow>
                         )
                     })}

                     { size(rows) === 0 &&
                       <TableRow>
                         <TableCell colSpan={6}>
                           No rows
                         </TableCell>
                       </TableRow>
                     }
                     {/* emptyRows > 0 && ( */
                         /*     <TableRow style={{ height: 53 * emptyRows }}> */
                         /*       <TableCell colSpan={6}> */
                         /*         No rows */
                         /*       </TableCell> */
                         /*     </TableRow> */
                         /* ) */}
                   </TableBody>
                 </>
                }
                {mobile &&
                 <TableBody>
                   { map(rows, (row, index) => {
                       const isItemSelected = isSelected(row.name)
                       const labelId = `enhanced-table-checkbox-${index}`

                       return (
                           <Tooltip title={"Click to view this company's page"} arrow>
                             <TableRow
                               hover
                               role="checkbox"
                               tabIndex={-1}
                               key={row.id}
                               onClick={() =>(handleRowClick(row, columns))}
                             >
                               <TableCell className={classes.mobileCell} width={'90%'}>
                                 {map(columns, function(column, index) {
                                     let value = null
                                     value = column.render ? column.render(row) : get(row, column.field)
                                     return value
                                 })}
                               </TableCell>
                               { size(actions) > 0 && showActions &&
                                 <TableCell className={[classes.actionBar, classes.mobileCell]}  align="right" width={50}>
                                   {map(actions, (action, index) => {
                                       return (
                                           <div key={index} className={classes.action} onClick={() => action.onClick(row)} >
                                             {action.icon}
                                           </div>
                                       )
                                   })}
                                 </TableCell>
                               }
                             </TableRow>
                           </Tooltip>
                       )
                   })}
                 </TableBody>
                }
              </Table>
            </TableContainer>

            <div>
              { renderPagination() }
            </div>

          </Grid>
        </Grid>
    )
}

export const CommonPaperTable = props => <Paper variant={"outlined"} square><CommonTable {...props} /></Paper>
