import { ItemList } from "../../orm";
import { get } from "lodash";

class AdminCountryPrintConfigurationsList extends ItemList {

  getEntityKey() {
    return "admin/country_print_configuration";
  }

}

export const adminCountryPrintConfigurationsList = new AdminCountryPrintConfigurationsList("admin_country_print_configurations__default");