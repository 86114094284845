import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import AdminMainLayout from './AdminMainLayout'
import Timestamp from '../../components/Timestamp'
import { adminLedgerTransactionsList } from '../actions/admin_ledger_transactions'
import { Button, Paper, Grid, makeStyles } from '@material-ui/core'

export const AdminDashboard = () => {

    return (
        <AdminMainLayout active_key={"dashboard"}
                         title="Dashboard">
          <Grid container justify="space-between">
            <Grid item>
              This is the Admin dashboard.
            </Grid>
          </Grid>
        </AdminMainLayout>
    )
}
