import { ItemList } from '../../orm'
import { uniq, head, uniqBy, map, filter, get } from "lodash"

class AdminLedgerTransactionsList extends ItemList {
  getEntityKey() {
    return "admin/ledger_transaction"
  }
  
  getLedgerTransactionById(ledger_transaction_id) {
    const ledgerTransaction = uniqBy(filter(this.getVisibleObjects(), (x) => x.id === ledger_transaction_id), (x) => x)
 
    return head(ledgerTransaction)
  }
  

}

export const adminLedgerTransactionsList = new AdminLedgerTransactionsList("admin_ledger_transactions__default")
