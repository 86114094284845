import React, { useState, useEffect } from "react"
import { get, map } from "lodash"
import { Button } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { countryList } from "../actions/admin_countries"
import { adminCurrencyConversionList } from "../actions/admin_currency_conversion"

export default function AdminCurrencyConversionForm({ currency_conversion, country_options }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { register, handleSubmit } = useForm({
    defaultValues: {
      country: get(currency_conversion, "country"),
      currency_code: get(currency_conversion, "currency_code"),
      conversion_rate_to_pound: get(
        currency_conversion,
        "conversion_rate_to_pound"
      )
    }
  })

  const onSubmit = (values) => {
    const on_done = function () {
      dispatch(countryList.invalidateList())
      dispatch(countryList.fetchListIfNeeded())
      history.push("/admin/currency_conversions")
    }

    if (get(currency_conversion, "id")) {
      values.id = get(currency_conversion, "id")
      return dispatch(adminCurrencyConversionList.saveObject(values)).then(on_done)
    } else {
      return dispatch(adminCurrencyConversionList.saveNewObject(values)).then(on_done)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <select {...register("country", { required: true })}>
        {map(country_options, (country_option) => (
          <option value={get(country_option, "id")}>
            {get(country_option, "country_name")}
          </option>
        ))}
      </select>
        <input
          type="text"
          placeholder="Conversion rate to pound"
          {...register("conversion_rate_to_pound")}
        />
      <Button type="submit">submit</Button>
    </form>
  )
}