import { ItemList } from "../orm"
import { get, size } from 'lodash'

class CutoutImageList extends ItemList {

    getEntityKey() {
        return "cutout_image"
    }

    ensureCutoutImageLoaded({imageWithCutout, photograph}) {
        if ( ! imageWithCutout || ! photograph ) {
            return {type: 'noop'}
        }
        const id = this.getId({imageWithCutout, photograph})
        return this.ensureObjectLoaded(id)
    }

    getCutoutImage({imageWithCutout, photograph}) {
        if ( ! imageWithCutout || ! photograph ) {
            return null
        }
        const id = this.getId({imageWithCutout, photograph})
        return this.getObject(id)
    }

    getId({imageWithCutout, photograph}) {
        return `${get(imageWithCutout, "id")}_${get(photograph, "id")}`
    }

    invalidateForCutout({imageWithCutout}) {
        // easier just to invalidate them all
        return this.invalidateAllObjects()
    }
    
}

export const cutoutImageList = new CutoutImageList("cutout_image__default")
