import React, { useEffect } from "react"
import { get } from "lodash"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import AdminCommonListLayout from "./AdminCommonListLayout"
import { adminCountryPrintConfigurationsList } from "../actions/admin_country_print_configurations"
import { countryList } from "../actions/admin_countries"

export const AdminCountryPrintConfigurations = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const is_loading = useSelector(() =>
  countryList.isLoading()
  )

  const columns = [
    {
      field: "name",
      title: "Country Name",
      render: (item) => (
        <p>
            {get(item, "country_name")}
        </p>
      )
    },
    {
      field: "Print",
      title: "Print",
      render: (item) => (
        <div>
          <button onClick={() => goToCountryPrintConfiguration(get(item, "country_print_configuration"))}>
            Print
          </button>
        </div>
      )
    },
  ]

  useEffect(() => {
    async function fetchStaticObjects() {
      dispatch(adminCountryPrintConfigurationsList.fetchListIfNeeded())
      dispatch(countryList.fetchListIfNeeded())
    }
    fetchStaticObjects()
  }, [])

  const goToCountryPrintConfiguration = (country_print_configuration_id) => {
    history.push(
      `/admin/country_print_configuration/${country_print_configuration_id}`
    )
  }

  return (
    <AdminCommonListLayout
      active_key="country_print_configurations"
      breadcrumbs={[
        { name: "admin_home" },
        {
          name: "country_print_configurations",
          label: "Country Print Configurations",
          url: "/admin/country_print_configurations",
        },
      ]}
      is_loading={is_loading}
      columns={columns}
      item_list={countryList}
    />
  )
}
